import React, { useState, useEffect } from "react";
import { Modal } from "@carbon/react";
import { ArrowLeft } from "@carbon/icons-react";
import FileUploader from "../Components/FileUploader";
import { api } from "../store";
import { useNavigate } from "react-router-dom";
import FileManager from "../Components/FileManager";

const ArchiveFiles = (searchError) => {
  const navigate = useNavigate();
  const [rowData, setRows] = useState([]);
  const [isModalOpen, setModalState] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filesAndStatus, setFilesAndStatus] = useState([]);
  const [uploadErrorMessages, setUploadErrorMessages] = useState([]);
  const [uploadError, setUploadError] = useState(false);
  const [userArchiveIDs, setUserArchiveIDs] = useState([]);
  const [isArchiveConfigured, setArchiveConfiguration] = useState(true);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [manualArchiveId, setManualArchiveId] = useState("");
  const [linkCLicked, setLinkClicked] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [currentFolderContents, setCurrentFolderContents] = useState([]);

  const getArchives = async (archiveIds) => {
    const idsString = archiveIds
      .map((item) => `${item.id}:${item.archiveType}`)
      .join(",");

    let response = await api.get(
      "/archives/getUserArchives?userArchivesId=" + idsString
    );

    const archives = response.data;
    setRows(archives);

    const filteredArchives = archives.filter((archive) => {
      // If currentFolderId is null, include files with parentDirId null or undefined
      if (currentFolderId === null) {
        return (
          archive.parentDirId === null || archive.parentDirId === undefined
        );
      }

      // Otherwise, match parentDirId with currentFolderId
      return archive.parentDirId === currentFolderId;
    });

    console.log(filteredArchives);
    setCurrentFolderContents(filteredArchives);
  };

  const getUserArchivesId = async () => {
    const response = await api.get("/archives/getUserArchivesId");
    if (response.data.archives.length > 0) {
      setArchiveConfiguration(true);
      setUserArchiveIDs(response.data.archives);
      const manualArchive =
        response.data.archives.find(
          (archive) => archive.archiveType === "uploadFiles"
        ) || null;
      if (manualArchive !== null) {
        setShowUploadButton(true);
        setManualArchiveId(manualArchive.id);
      }
      getArchives(response.data.archives);
    } else {
      setArchiveConfiguration(false);
      setUserArchiveIDs([]);
    }
  };

  useEffect(() => {
    //get archive IDs of the user
    getUserArchivesId();
  }, []);

  async function deleteFile(fileId) {
    try {
      // Send delete request to the server
      const response = await api.delete("/file/deleteFile", {
        params: {
          fileId: fileId,
          ownerId: manualArchiveId,
        },
      });
      return response.data.message;
    } catch (err) {
      throw err;
    }
  }

  async function uploadFiles(files) {
    const formData = new FormData();

    files.forEach((file) => formData.append("files", file));
    formData.append("ownerId", manualArchiveId);
    formData.append("fileTag", "archive");
    console.log(currentFolderId);
    formData.append("parentDirId", currentFolderId);
    try {
      const response = await api.post("file/uploadFiles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  }

  async function embedFile(fileId) {
    try {
      await api.post("ai/embed", {
        fileId,
        ownerId: manualArchiveId,
      });
    } catch (err) {
      throw err;
    }
  }

  const submitUploadFiles = async () => {
    let updatedFilesAndStatus = filesAndStatus.map((fs) =>
      fs.status === "edit" ? { ...fs, status: "uploading" } : fs
    );

    try {
      setFilesAndStatus(updatedFilesAndStatus);
      setUploadErrorMessages([]);

      // Only attempt upload if status is 'uploading'
      const filesToUpload = updatedFilesAndStatus
        .filter((fs) => fs.status === "uploading")
        .map((fs) => fs.file);

      const uploadResponses =
        filesToUpload.length > 0 ? (await uploadFiles(filesToUpload)).data : [];

      for (let [index, response] of uploadResponses.entries()) {
        // let index = updatedFilesAndStatus.findIndex(fs => fs.file.name === response.fileName);
        if (response.status === 200) {
          try {
            await embedFile(response.filePath);
            updatedFilesAndStatus[index].status = "complete";
          } catch (embedError) {
            console.log("Embed failed for ", response.fileName, embedError);
            try {
              await deleteFile(response.filePath);
            } catch (deleteError) {
              console.log(deleteError);
            }
            updatedFilesAndStatus[index].status = "edit";
            setUploadErrorMessages((prevErrorMessages) => [
              ...prevErrorMessages,
              `Could not upload file: ${response.fileName}`,
            ]);
          }
        } else {
          console.log("File upload failed for ", response.fileName);
          updatedFilesAndStatus[index].status = "edit";
          setUploadErrorMessages((prevErrorMessages) => [
            ...prevErrorMessages,
            response.message,
          ]);
        }
      }
    } catch (error) {
      updatedFilesAndStatus = filesAndStatus.map((fs) => {
        fs.status = "edit";
        return fs;
      });
      console.error("Error during file upload process", error);
      setUploadError(true);
    } finally {
      setFilesAndStatus(updatedFilesAndStatus);
      const completedFilesAndStatus = updatedFilesAndStatus.filter(
        (fs) => fs.status === "complete"
      );
      if (completedFilesAndStatus.length === filesAndStatus.length) {
        setModalState(false);
        setFilesAndStatus([]);
        setUploadErrorMessages([]);
      }
      getArchives(userArchiveIDs);
    }
  };

  function updateFileUploaderStatus(updatedFilesAndStatus) {
    setUploadErrorMessages([]);
    setFilesAndStatus(updatedFilesAndStatus);
    setUploadError(false);
  }

  const handleArchiveFileDelete = async (event) => {
    event.preventDefault(); // Prevents the link from navigating to the URL
    for (let i = 0; i < selectedRows.length; i++) {
      const selRow = selectedRows[i];
      const record = rowData.find((item) => item.id === selRow.id);
      const archiveId = record.archiveId;
      // First delete the embedding from the Vector DB
      let response = await api.delete(
        "/archives/deleteFileVector?fileName=" + selRow.id
      );
      // Also delete the file from GCP
      if (response.status === 200) {
        let result = await api.delete("/file/deleteFile", {
          params: {
            fileId: selRow.id,
            ownerId: archiveId,
          },
        });
      }
    }

    //refresh the table
    setSelectedRows([]);
    getArchives(userArchiveIDs);
  };

  function getFileNameFromPath(path) {
    // Extracts the file name from the path
    if (path !== undefined) {
      const fileId = String(path).split("/").pop();
      const foundItem = rowData.find((item) => item.id === fileId);
      return foundItem ? foundItem.name : fileId;
    } else return path;
  }

  const handleArchiveFile = async (fileId) => {
    try {
      let foundItem = rowData.find((item) => item.id === fileId);
      if (foundItem === undefined)
        foundItem = rowData.find((item) => item.name === path);
      const response = await api.post("file/download", null, {
        params: {
          id: foundItem.id,
          ownerId: foundItem.archiveId,
        },
      });
      let contents;
      if (response.data.fileType === "application/pdf") {
        contents = response.data.url;
        window.open(contents, "_blank");
      } else {
        contents = response.data.contents;
        var newTab = window.open();
        newTab.document.write(contents.replace(/\n/g, "<br>"));
        newTab.document.close();
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  const createFolder = async (name) => {
    try {
      await api.post("file/createFolder", {
        name,
        ownerId: manualArchiveId,
        parentDirId: currentFolderId != null ? currentFolderId : null,
      });
    } catch (err) {
      throw err;
    }
  };

  return (
    <div style={{ margin: "1rem 2rem" }}>
      <button onClick={() => navigate("/ask-archives")}>
        <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <ArrowLeft style={{ width: "0.8rem", height: "0.8rem" }} />
          <span style={{ fontSize: "var(--text-small)" }}>Back</span>
        </div>
      </button>
      <div className="page-header">
        <div className="page-title-container">
          <span className="page-title">Archive Files</span>
          {/* {showUploadButton && (
            <Button
              onClick={(e) => {
                setModalState(true);
                e.stopPropagation();
              }}
              kind="primary"
              className="create-project-button"
            >
              Upload files
            </Button>
          )} */}
        </div>
      </div>
      <div style={{ margin: "0 auto auto 84%" }}>
        <Modal
          open={isModalOpen}
          modalHeading="Upload to Archives "
          primaryButtonText="Upload"
          secondaryButtonText="Cancel"
          onRequestSubmit={submitUploadFiles}
          onRequestClose={() => {
            setModalState(false);
          }}
          size="sm"
        >
          <span style={{ display: "block" }}>
            Accepted formats: .txt, .docx, .pdf
          </span>
          <span style={{ display: "block" }}>Maximum file size is 25MB.</span>
          <div style={{ position: "relative" }}>
            <FileUploader
              uploadErrorMessages={uploadErrorMessages}
              filesAndStatus={filesAndStatus}
              updateFileUploaderStatus={updateFileUploaderStatus}
              maxFiles={100}
              uploadError={uploadError}
              allowAudioFiles={false}
              acceptTypes={[".txt", ".docx", ".pdf"]}
            />
          </div>{" "}
          <br></br>
        </Modal>
      </div>
      <div style={{ padding: "0 1.3rem" }}>
        <div style={{ padding: "0 1.3rem" }}>
          <FileManager
            uploadFile={() => setModalState(true)}
            setFolderId={(folderId) => {
              console.log("this dddd");
              setCurrentFolderId(folderId);

              const filteredArchives = rowData.filter((archive) => {
                // Check if parentDirId matches folderId or is undefined/null
                return folderId === null
                  ? archive.parentDirId === undefined ||
                      archive.parentDirId === null
                  : archive.parentDirId === folderId;
              });

              console.log(filteredArchives);
              setCurrentFolderContents(filteredArchives);
            }}
            createFolder={createFolder}
            currentFolderContents={currentFolderContents}
            handleArchiveFileClick={handleArchiveFile}
            deleteFile={handleArchiveFileDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default ArchiveFiles;
