import React, { useState, useEffect } from "react";
import { api } from "../store";
import "./SharepointConfig.scss";
// import { GraphFileBrowser } from "@microsoft/file-browser";

const SharePointConfig = ({ isAuthTriggered }) => {
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (isAuthTriggered) {
      handleConnectSharePoint();
      window.addEventListener("message", handleAuthMessage);
      checkStoredAuthCode();

      return () => window.removeEventListener("message", handleAuthMessage);
    }
  }, [isAuthTriggered]);

  const checkStoredAuthCode = () => {
    const storedCode = localStorage.getItem("sharepointAuthCode");
    if (storedCode) {
      handleSharePointAuth(storedCode);
      localStorage.removeItem("sharepointAuthCode");
    }
  };

  const handleAuthMessage = (event) => {
    if (event.data.type === "SHAREPOINT_AUTH_SUCCESS") {
      handleSharePointAuth(event.data.code);
    }
  };

  const handleSharePointAuth = async (code) => {
    try {
      const response = await api.get(
        `/sharepoint/auth/azuread/callback?code=${code}`
      );

      const { token, refreshToken } = response.data;
      console.log(token);
      storeTokensInArchive(token, refreshToken);
      setToken(token);
    } catch (error) {
      console.error("Error handling SharePoint callback:", error);
      setError("Error authenticating with SharePoint");
    }
  };

  const handleConnectSharePoint = async () => {
    try {
      const response = await api.get("/sharepoint/login-url");
      window.open(
        response.data.authUrl,
        "SharePoint Login",
        "width=600,height=600"
      );
    } catch (error) {
      console.error("Error fetching login URL:", error);
      setError("Error fetching login URL");
    }
  };

  const storeTokensInArchive = async (accessToken, refreshToken) => {
    try {
      await api.post("/archives/createArchive", {
        archiveType: "sharePoint",
        archiveURL: "https://sharepoint.com",
        archiveAuthentication: "OAuth",
        archiveCredentials: {
          accessToken: accessToken,
          refreshToken: refreshToken,
        },
      });
      console.log("Tokens stored successfully in the archive.");
    } catch (error) {
      console.error("Error storing tokens in the archive:", error);
    }
  };

  const getAuthenticationToken = () => {
    return new Promise((resolve) => {
      console.log(token);
      resolve(token);
    });
  };

  const handleFileSelection = (selectedKeys) => {
    console.log("Selected files:", selectedKeys);
  };

  const handleCancelSelection = (err) => {
    console.error("File selection cancelled:", err.message);
  };

  return (
    <div>
      {error && (
        <div className="graph-file-browser-container">Error: {error}</div>
      )}

      {token && (
        <div className="graph-file-browser-container">
          {/* <GraphFileBrowser
            getAuthenticationToken={getAuthenticationToken}
            onSuccess={(selectedItems) => handleFileSelection(selectedItems)}
            onCancel={handleCancelSelection}
            selectionMode="multiple"
            endpoint="https://graph.microsoft.com/v1.0/me"
          /> */}
        </div>
      )}
    </div>
  );
};

export default SharePointConfig;
