import React, { useState } from "react";
import { Grid, Column, InlineLoading } from "@carbon/react";
import { api_external } from "../store";
import "./Dashboard.scss";
import env from "../config";

// import "react-chat-widget/lib/styles.css";
import ChatWidget from "../Components/ChatWidget";
import FinForm from "../Components/GenerateFinEstimates";
import ExcelLikeGrid from "../Components/FinPubOutput";

const FinPub = () => {
  const [displayForm, setDisplayForm] = useState(true);
  const [csvData, setCSVData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    formData,
    annualReportsFiles,
    quarterlyReports
  ) => {
    setDisplayForm(false);
    setIsLoading(true);
    const data = new FormData();

    console.log(formData);
    data.append("company_name", formData.companyName);
    data.append("financial_doc", formData.docType);

    annualReportsFiles.forEach((file, index) => {
      data.append(`annual_reports`, file);
    });

    quarterlyReports.forEach((file, index) => {
      data.append(`quarterly_reports`, file);
    });

    try {
      const response = await api_external.post(
        env.finPubAPI + "/fin-doc-estimates",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setCSVData(response.data.csv);
      setIsLoading(false);
    } catch (error) {
      console.error("Error calling the api:", error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Grid style={{ marginTop: "4rem" }} className="grid-dashboard">
        <Column lg={14} className="rounded-left column-dashboard">
          {displayForm && !isLoading && (
            <div style={{ margin: "2% 5% 0 5%" }}>
              <div style={{ width: "50%" }}>
                <FinForm
                  onSubmit={(formData, annualReportsFiles, quarterlyReports) =>
                    handleSubmit(formData, annualReportsFiles, quarterlyReports)
                  }
                />
              </div>
            </div>
          )}
          {isLoading && (
            <div style={{ margin: "2% 10% 0 10%" }}>
              <InlineLoading
                description="Hang tight! We are generating group financials."
                status="active"
              />
            </div>
          )}
          {!displayForm && csvData != null && !isLoading && (
            <div style={{ margin: "2% 10% 0 10%" }}>
              <div style={{ width: "100%" }}>
                {!displayForm && (
                  <div>
                    <ExcelLikeGrid
                      csvData={csvData}
                      financial_name="Cash flow"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </Column>
      </Grid>
    </div>
  );
};

export default FinPub;
