import React, { useState, useCallback, useEffect } from "react";
import {
  Paper,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Divider,
  IconButton,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

// Styled components (unchanged)
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  position: "relative",
}));

const NoDataText = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: 1.2,
  color: "#757575",
  wordBreak: "break-word",
  overflowWrap: "break-word",
}));

const ListItemPrimaryText = styled("span")(({ theme }) => ({
  fontSize: "0.875rem !important",
  lineHeight: 1.2,
}));

const ListItemSecondaryText = styled("span")(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: 1.2,
  color: "#757575",
  wordBreak: "break-word",
  overflowWrap: "break-word",
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const renderSectionOrChecklist = (
  title,
  data,
  onItemToggle = null,
  onEdit,
  onSave,
  onAdd
) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState(data || []);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setEditedData(data || []);
  }, [data]);

  const handleEdit = (index, field, value) => {
    const newData = [...editedData];
    newData[index][field] = value;
    setEditedData(newData);
  };

  const handleAdd = () => {
    setEditedData([...editedData, { item: "", value: "" }]);
  };

  const handleSave = () => {
    const newErrors = editedData.map((item) => ({
      item: item.value && item.value.trim() && !item.item.trim(),
      value: item.item.trim() && item.value && !item.value.trim(),
    }));

    setErrors(newErrors);

    if (newErrors.some((error) => error.item || error.value)) {
      return;
    }

    onSave(
      title,
      editedData.filter((item) => item.item.trim() || item.value?.trim())
    );
    setIsEditing(false);
  };

  const renderActionButton = () => (
    <ActionButton onClick={isEditing ? handleSave : () => setIsEditing(true)}>
      {isEditing ? (
        <CancelRoundedIcon sx={{ fontSize: "1.2rem" }} />
      ) : (
        <EditIcon sx={{ fontSize: "1.2rem", color: "darkgrey" }} />
      )}
    </ActionButton>
  );

  const renderContent = () => {
    if (editedData.length === 0 && !isEditing) {
      return <NoDataText>No data available for {title}</NoDataText>;
    }
    const filteredData = data.filter(
      (item) => item.value !== null && item.value !== undefined
    );

    if (filteredData.length === 0 && !isEditing) {
      return <NoDataText>No data available for {title}</NoDataText>;
    }

    return (
      <>
        <List>
          {editedData.map((item, index) => (
            <ListItem
              key={index}
              style={{
                padding: onItemToggle ? "0 8px" : "8px",
                alignItems: "flex-start",
              }}
            >
              {onItemToggle && (
                <Checkbox
                  edge="start"
                  checked={item.checked || false}
                  tabIndex={-1}
                  onChange={() => onItemToggle(title, index)}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 20, color: "#55828b" },
                    padding: "8px 8px",
                  }}
                />
              )}
              <ListItemText
                primary={
                  isEditing ? (
                    <TextField
                      value={item.item}
                      onChange={(e) =>
                        handleEdit(index, "item", e.target.value)
                      }
                      fullWidth
                      variant="standard"
                      placeholder="Title"
                      helperText={
                        errors[index]?.item && "Title cannot be empty"
                      }
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "0.875rem",
                          // fontWeight: "bold",
                          padding: 0,
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: "4px",
                          margin: "0 0 1px 0",
                          border: "2px solid lightgrey",
                          "&:hover, &.Mui-focused": {
                            border: "2px solid #719fa8",
                          },
                        },
                      }}
                    />
                  ) : (
                    item.item
                  )
                }
                secondary={
                  isEditing ? (
                    <TextField
                      value={item.value}
                      onChange={(e) =>
                        handleEdit(index, "value", e.target.value)
                      }
                      fullWidth
                      multiline
                      variant="standard"
                      placeholder="Description"
                      helperText={
                        errors[index]?.value && "Value cannot be empty"
                      }
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "0.875rem",
                          color: "#757575",
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          border: "2px solid lightgrey",
                          borderRadius: "4px",
                          "&:hover, &.Mui-focused": {
                            border: "2px solid #719fa8",
                          },
                        },
                        "& .MuiInputBase-input": {
                          padding: "1px 0px",
                        },
                      }}
                    />
                  ) : (
                    item.value
                  )
                }
                primaryTypographyProps={{
                  component: ListItemPrimaryText,
                }}
                secondaryTypographyProps={{
                  component: ListItemSecondaryText,
                }}
              />
            </ListItem>
          ))}
        </List>
        {isEditing && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              startIcon={<AddIcon />}
              onClick={handleAdd}
              variant="outlined"
              size="small"
            >
              Add Item
            </Button>
          </Box>
        )}
      </>
    );
  };

  return (
    <StyledPaper elevation={3}>
      <Typography variant="sectionHeading" sx={{ color: "black" }}>
        {title}
      </Typography>
      {renderActionButton()}
      <Divider sx={{ mt: 2, px: 2 }} />
      {renderContent()}
    </StyledPaper>
  );
};

// Main RFPChecklist component
const RFPChecklist = ({ RFPData, onRFPDataUpdate }) => {
  const [localRFPData, setLocalRFPData] = useState(RFPData || {});

  useEffect(() => {
    if (RFPData && JSON.stringify(RFPData) !== JSON.stringify(localRFPData)) {
      setLocalRFPData(RFPData);
    }
  }, [RFPData]);

  const handleItemToggle = useCallback(
    (sectionTitle, itemIndex) => {
      setLocalRFPData((prevData) => {
        const newData = { ...prevData };
        const sectionKey = sectionTitle.toLowerCase().replace(/ /g, "_");
        const section = newData[sectionKey];
        if (section && Array.isArray(section)) {
          section[itemIndex] = {
            ...section[itemIndex],
            checked: !section[itemIndex].checked,
          };
        }
        onRFPDataUpdate(newData);
        return newData;
      });
    },
    [onRFPDataUpdate]
  );

  const handleSave = (sectionTitle, newData) => {
    setLocalRFPData((prevData) => {
      const newLocalData = { ...prevData };
      const sectionKey = sectionTitle.toLowerCase().replace(/ /g, "_");
      newLocalData[sectionKey] = newData;
      onRFPDataUpdate(newLocalData);
      return newLocalData;
    });
  };

  const renderGridItem = (sections, size, useChecklist = false) => (
    <Grid item xs={12} md={size}>
      {sections.map((section) =>
        renderSectionOrChecklist(
          section.title,
          localRFPData[section.key],
          useChecklist ? handleItemToggle : null,
          null,
          handleSave
        )
      )}
    </Grid>
  );

  const sections = [
    { title: "Basic Details", key: "basic_details" },
    { title: "Contact Details", key: "contact_details" },
    { title: "Timelines", key: "rfp_project_timelines" },
    { title: "Budget & Payment Details", key: "budget_payment" },
    { title: "Scope of Work", key: "scope_of_work" },
    { title: "Evaluation Criteria", key: "evaluation_criteria" },
    { title: "Other Requirements", key: "other_requirements" },
    { title: "Additional Notes", key: "additional_notes" },
  ];

  const checklistSections = [
    {
      title: "RFP Response Documents Checklist",
      key: "rfp_response_documents_checklist",
    },
    { title: "Project Deliverables", key: "project_deliverables" },
    { title: "Warranty", key: "warranty" },
    {
      title: "Response Formatting Requirements",
      key: "response_formatting_requirements",
    },
  ];

  return (
    <Grid
      container
      spacing={1}
      style={{
        padding: "0px 0.5rem 0px 0.5rem",
        height: "100%",
        overflowY: "auto",
      }}
    >
      {renderGridItem(sections.slice(0, 4), 3)}
      {renderGridItem(sections.slice(4), 5)}
      {renderGridItem(checklistSections, 4, true)}
    </Grid>
  );
};

export default RFPChecklist;
