const Divider = () => {
  return (
    <div
      style={{
        height: "1.5px",
        backgroundColor: "#808080",
        margin: "0.5rem 0.5rem",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
      }}
    ></div>
  );
};

export default Divider;
