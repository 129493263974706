// src/store/selectedItemSlice.js
import { createSlice } from "@reduxjs/toolkit";

const selectedItemSlice = createSlice({
  name: "selectedItem",
  initialState: {},
  reducers: {
    setSelectedItem: (state, action) => {
      return action.payload;
    },
    updateSelectedItemContent: (state, action) => {
      if (state.uuid === action.payload.uuid) {
        return { ...state, ...action.payload };
      }
      return state;
    },
  },
});

export const { setSelectedItem, updateSelectedItemContent } =
  selectedItemSlice.actions;
export default selectedItemSlice.reducer;
