import React, { useState, useEffect } from "react";
import {
  Modal,
  ProgressIndicator,
  ProgressStep,
  Toggle,
  Dropdown,
  TextArea,
  TextInput,
  Tooltip,
  SkeletonText,
  SkeletonPlaceholder,
} from "@carbon/react";
import {
  Launch,
  Copy,
  Information,
  Favorite,
  FavoriteFilled,
} from "@carbon/icons-react";
import "./scss/GenerationModal.scss";
import { api, api_external } from "../../store";
import {
  projectUIFileFormatsToFilter,
  fileTagsForGenMainPoints,
} from "../../util";
import env from "../../config";
import AddWebSource from "./../AddWebSource";
import KeywordTagInput from "../KeywordInput";
import AddFileResource from "../AddFileResource";
import { useNavigate, useLocation } from "react-router-dom";

const GenerateWebLinksLoader = () => {
  return (
    <div>
      <span style={{ fontSize: "var(--text-medium)" }}>
        Fetching research from the web
      </span>
      <div>
        <SkeletonText
          width="100%"
          style={{
            marginTop: "4%",
            borderRadius: "var(--border-radius)",
            height: "1.5rem",
          }}
        />
      </div>
      <div>
        <SkeletonPlaceholder
          style={{
            marginTop: "5%",
            width: "100%",
            height: "5rem",
            borderRadius: "var(--border-radius)",
          }}
        />
        <SkeletonPlaceholder
          style={{
            marginTop: "5%",
            width: "100%",
            height: "5rem",
            borderRadius: "var(--border-radius)",
          }}
        />
        <SkeletonPlaceholder
          style={{
            marginTop: "5%",
            width: "100%",
            height: "5rem",
            borderRadius: "var(--border-radius)",
          }}
        />
      </div>
    </div>
  );
};

const IdeaGenerationModal = ({
  isOpen,
  onClose,
  refreshFiles,
  projectId,
  refreshComponent,
  generateFileName,
  saveFile,
  addTab,
  setOutput,
  setTabLoading,
  tabIndex,
  setShowMsg,
  setLoadingMsg,
  onContentChange,
  setShowErrorMsg,
  setErrorMsg,
  setMissedKeywords,
  idea,
}) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [primaryButtonText, setPrimaryButtonText] = useState("Proceed");
  const [secondaryButtonText, setSecondaryButtonText] = useState("Cancel");
  const [objective, setObjective] = useState({
    idea: "",
    style: "",
  });
  const [isTopicError, setIsTopicError] = useState(false);
  const [topicErrorMsg, setTopicErrorMsg] = useState("Value cannot be empty");
  const [isOtherError, setOtherIsError] = useState(false);
  const [projectFiles, setProjectFiles] = useState([]);
  const [styleProfiles, setStyleProfiles] = useState([]);
  const [selectedFileRoles, setSelectedFileRoles] = useState({});
  const [webSources, setWebSources] = useState([]);
  const [userWebSources, setUserWebSources] = useState([]);
  const [isFetchingWebLinks, setIsFetching] = useState(false);
  const [isFetchingSources, setIsFetchingSources] = useState(false);
  const [seoKeywords, setSEOKeywords] = useState([]);
  const [addPendingKeyword, setAddPendingKeyword] = useState(false);
  const [doWebResearch, setDoWebResearch] = useState(true);
  const [preferredSourcesList, setPreferredSourcesList] = useState([]);
  const [user, setUser] = useState(null);

  // const preferenceList = [
  //   {
  //     label: "auto finance",
  //     preference_sources: [
  //       "mckinsey.com",
  //       "linkedin.com",
  //       "wsj.com",
  //       "accountsrecovery.net",
  //       "insidearm.com",
  //       "autofinancenews.net",
  //       "bankingjournal.aba.com",
  //       "bain.com",
  //       "bcg.com",
  //       "kpmg.com",
  //       "deloitte.com",
  //       "ey.com",
  //       "pwc.com",
  //       "prodigaltech.com",
  //       "cbsnews.com",
  //       "usatoday.com",
  //       "experian.com",
  //       "bankrate.com",
  //       "cnn.com",
  //       "ft.com",
  //       "nytimes.com",
  //       "edmunds.com",
  //       "cnbc.com",
  //       "autofinance.live",
  //       "imbibemagazine.com",
  //       "marketwatch.com",
  //       "autonews.com",
  //       "coxautoinc.com",
  //       "reuters.com",
  //     ],
  //   },
  //   {
  //     label: "collections",
  //     preference_sources: [
  //       "mckinsey.com",
  //       "linkedin.com",
  //       "wsj.com",
  //       "accountsrecovery.net",
  //       "insidearm.com",
  //       "bankingjournal.aba.com",
  //       "bain.com",
  //       "bcg.com",
  //       "kpmg.com",
  //       "deloitte.com",
  //       "ey.com",
  //       "pwc.com",
  //       "prodigaltech.com",
  //       "ibisworld.com",
  //       "collectionsandrecovery.com",
  //       "libertystreeteconomics.newyorkfed.org",
  //       "shrm.org",
  //       "pewresearch.org",
  //       "ftc.gov",
  //       "cnb.cx",
  //       "rmaintl.org",
  //       "blog.marketresearch.com",
  //       "consumerfinance.gov",
  //       "bls.gov",
  //       "insidearm.com",
  //       "finance.yahoo.com",
  //       "washingtonpost.com",
  //       "federalreserve.gov",
  //       "forbes.com",
  //       "jdsupra.com",
  //       "newsroom.transunion.com",
  //       "cdc.gov",
  //       "consumerbankers.com",
  //       "acainternational.org",
  //       "businessinsider.com",
  //       "hellenicshippingnews.com",
  //       "files.consumerfinance.gov",
  //       "whitehouse.gov",
  //       "propertymanagement.com",
  //       "cdn.nar.realtor",
  //       "schgroup.com",
  //       "usatoday.com",
  //       "nada.org",
  //       "newyorkfed.org",
  //       "axios.com",
  //       "educationdata.org",
  //       "troutman.com",
  //       "consumerfinancemonitor.com",
  //       "nytimes.com",
  //       "bankrate.com",
  //       "federalregister.gov",
  //       "goanywhere.com",
  //       "fedscoop.com",
  //       "nerdwallet.com",
  //       "arxiv.org",
  //       "blog.gitnux.com",
  //       "accountsrecovery.net",
  //       "urban.org",
  //       "cnbc.com",
  //       "creditandcollectionnews.com",
  //       "collectionrecoverysolutions.com",
  //       "experian.com",
  //     ],
  //   },
  //   {
  //     label: "healthcare rcm",
  //     preference_sources: [
  //       "mckinsey.com",
  //       "linkedin.com",
  //       "wsj.com",
  //       "accountsrecovery.net",
  //       "insidearm.com",
  //       "bain.com",
  //       "bcg.com",
  //       "kpmg.com",
  //       "deloitte.com",
  //       "ey.com",
  //       "pwc.com",
  //       "prodigaltech.com",
  //       "consumerfinance.gov",
  //       "news.gallup.com",
  //       "weaver.com",
  //       "bankrate.com",
  //       "forbes.com",
  //       "healthleadersmedia.com",
  //       "aha.org",
  //       "accelerate.uofuhealth.utah.edu",
  //       "medicaleconomics.com",
  //       "nytimes.com",
  //       "beckershospitalreview.com",
  //     ],
  //   },
  //   {
  //     label: "lending",
  //     preference_sources: [
  //       "mckinsey.com",
  //       "linkedin.com",
  //       "wsj.com",
  //       "accountsrecovery.net",
  //       "insidearm.com",
  //       "autofinancenews.net",
  //       "bankingjournal.aba.com",
  //       "bain.com",
  //       "bcg.com",
  //       "kpmg.com",
  //       "deloitte.com",
  //       "ey.com",
  //       "pwc.com",
  //       "prodigaltech.com",
  //       "fdic.gov",
  //       "fortune.com",
  //       "consumerbankers.com",
  //       "aba.com",
  //       "reuters.com",
  //       "lendingtree.com",
  //       "cdc.gov",
  //       "forbes.com",
  //       "nytimes.com",
  //       "fintechnexus.com",
  //       "consumerfinance.gov",
  //       "lexmachina.com",
  //       "acainternational.org",
  //       "nafcu.org",
  //       "americanbanker.com",
  //       "cutimes.com",
  //       "revcycleintelligence.com",
  //     ],
  //   },
  // ];

  useEffect(() => {
    setObjective({ idea: idea, style: "List blog post" });
    if (isOpen) {
      getObjective();
      const profile = localStorage.getItem("profile");
      const profileJSON = JSON.parse(profile);
      setUser(profileJSON);
      getUserStyleProfiles();
      getFactcheckProfile();
    }

    async function getObjective() {
      try {
        let response = await api.post("file/download", null, {
          params: {
            name: "objective.json",
            ownerId: projectId,
          },
        });
        const contents = response.data.contents;
        let contentJSON = JSON.parse(contents);
        if (idea != "") contentJSON.idea = idea;
        if (contentJSON != null) setObjective(contentJSON);
      } catch (err) {
        console.log(err);
      }
    }

    async function getUserStyleProfiles() {
      try {
        let response = await api.get("style/getUserStyles");
        // Extract the names from the response
        let styleProfiles = response.data.map((profile) => profile.name);

        // Append the values from the Select component
        const selectOptions = [
          "Inverted pyramid",
          "Narrative",
          "Problem-Solution",
          "Compare and Contrast",
        ];
        if (styleProfiles.length > 0) {
          setStyleProfiles(styleProfiles);
        } else {
          styleProfiles = [...styleProfiles, ...selectOptions];
          setStyleProfiles(styleProfiles);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [isOpen, refreshComponent]);

  async function getFactcheckProfile() {
    try {
      const response = await api.get("/factcheck/getFactcheckProfile");
      if (response.data === null) {
        await createFactcheckProfile();
      } else setPreferredSourcesList(response.data.allow_list);
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async function createFactcheckProfile() {
    try {
      const allowList = [
        "mckinsey.com",
        "wsj.com",
        "bain.com",
        "bcg.com",
        "kpmg.com",
        "deloitte.com",
        "ey.com",
        "pwc.com",
      ];
      const disallowList = [
        "quora.com",
        "reddit.com",
        "tiktok.com",
        "instagram.com",
        "wikipedia.org",
        "facebook.com",
        "linkedin.com",
      ];
      await api.post("/factcheck/createFactcheckProfile", {
        allowList: allowList,
        disallowList: disallowList,
        description: "",
      });
      setPreferredSourcesList(allowList);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async function updateFactcheckProfile(link) {
    try {
      const newItem = extractDomain(link);
      const allow_list = [...preferredSourcesList, newItem];
      await api.put("/factcheck/updateFactcheckProfile", {
        allowList: allow_list,
      });
      setPreferredSourcesList(allow_list);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async function fetchInputOutputFiles() {
    try {
      const fileList = await api.get("file/getFiles", {
        params: { projectId: projectId },
        headers: { "Cache-Control": "no-cache" },
      });
      // Replace with user selection in future versions
      const userFiles = fileList.data.filter(
        (file) =>
          !projectUIFileFormatsToFilter.some((format) =>
            file.name.includes(format)
          )
      );
      const mainpointFiles = userFiles.filter((file) =>
        fileTagsForGenMainPoints.some((tag) => file.tag.includes(tag))
      );

      setProjectFiles(mainpointFiles);
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchWebSources() {
    setIsFetching(true);
    try {
      const { idea } = objective;
      // set file roles to empty
      setSelectedFileRoles({});
      setUserWebSources([]);
      const factcheckProfile = await getFactcheckProfile();
      const disallowedList = factcheckProfile
        ? factcheckProfile.disallow_list
        : [];
      const preferred_sources = factcheckProfile
        ? factcheckProfile.allow_list
        : [];

      const requestData = {
        idea: idea.trim(),
        industry: user.industry != null ? user.industry : "",
        preference_list: preferred_sources,
        disallow_list: disallowedList,
      };
      const response = await api_external.post(
        env.salesPubAPI + "/web-links-from-idea",
        requestData
      );
      const results = response.data.webSources;
      //log the call
      api.post("/user/logAsyncServiceCall", {
        projectId: projectId,
        service_tag: "generateWebLinks",
        service_parent_id: null,
        service_filename: null,
        options: {
          service_progress_status: false,
          service_payload: {
            input: {
              idea: idea,
              industry: user.industry,
              preference_list: preferred_sources,
              disallow_list: disallowedList,
            },
            output: response.data,
          },
        },
        logs: response.data.usage,
      });
      setWebSources(results);
    } catch (error) {
      console.log(error);
      setIsTopicError(true);
      setTopicErrorMsg(
        "Web sources could not be generated. Retry with a clear topic or contact xpub at support@xpub.ai"
      );
      setStep(1);
      setWebSources([]);
    } finally {
      setIsFetching(false);
    }
  }

  async function fetchSources() {
    setIsFetchingSources(true);
    setWebSources([]);
    setUserWebSources([]);
    await fetchInputOutputFiles();
    if (doWebResearch) {
      await fetchWebSources();
    }

    setIsFetchingSources(false);
  }

  async function addAnyPendingKeywords() {
    setAddPendingKeyword(true);
    // Wrap setTimeout in a Promise to await it
    await new Promise((resolve) =>
      setTimeout(() => {
        setAddPendingKeyword(false);
        resolve();
      }, 1000)
    );
  }

  const handleNextStep = async (evt) => {
    setShowErrorMsg(false);
    if (step === 1) {
      evt.preventDefault();
      await addAnyPendingKeywords();

      const { idea, style } = objective;
      if (idea === "") {
        setIsTopicError(true);
        setTopicErrorMsg("Value cannot be empty");
      }
      if (style === "" || style === undefined) setOtherIsError(true);

      if (idea === "" || style === "" || style === undefined) {
        return;
      }
      if (
        isTopicError &&
        !isOtherError &&
        topicErrorMsg ===
          "Web sources could not be generated. Retry with a clear topic or contact xpub at support@xpub.ai"
      ) {
        setStep(2);
        setPrimaryButtonText("Proceed");
        setSecondaryButtonText("Back");
        return;
      }
      setStep(2);
      setPrimaryButtonText("Proceed");
      setSecondaryButtonText("Back");
      await fetchSources();
    } else {
      evt.preventDefault();
      let response;
      let style_profile = objective.style;
      let style = objective.style;
      let style_files = [];

      try {
        response = await api.get("style/getStyleParameters", {
          params: { name: style_profile },
        });
        if (response.data != "" && response.status != 204) {
          style = response.data.description;
          style_files = response.data.files;
        }
      } catch (error) {
        style = objective.style;
      }

      const sourcesList = Object.entries(selectedFileRoles).map(
        ([id, { title, type, file_tags, mime }]) => ({
          title,
          type,
          id,
          file_tags,
          mime,
        })
      );

      // call the text gen API
      // objective.style = style;
      objective.idea = objective.idea.trim();
      generateSEOContent(sourcesList, style_files);

      onModalClose();
    }
  };

  const handleSecondaryButtonClick = () => {
    if (step === 1) onModalClose();
    else if (step === 2) {
      setStep(1);
      setPrimaryButtonText("Proceed");
      setSecondaryButtonText("Cancel");
    }
  };

  const handleStyleDropdownChange = ({ selectedItem }) => {
    setOtherIsError(false);
    if (selectedItem) {
      setObjective((prev) => ({
        ...prev,
        style: selectedItem.id,
      }));
    }
  };

  const handleTileClick = (title, id, type, mime, tags) => {
    const selectedCount = Object.values(selectedFileRoles).filter(
      (val) => val.selected
    ).length;
    if (selectedFileRoles[id]?.selected) {
      setSelectedFileRoles((prev) => {
        const updated = { ...prev };
        delete updated[id];
        return updated;
      });
    } else {
      if (selectedCount < 4) {
        setSelectedFileRoles((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            selected: true,
            title: title,
            type: type,
            file_tags: tags,
            mime: mime,
          },
        }));
      }
    }
  };

  const onModalClose = () => {
    onClose();
    setStep(1);
    setSelectedFileRoles({});
    setSecondaryButtonText("Cancel");
    setSEOKeywords([]);
    setTopicErrorMsg("");
    setIsTopicError(false);
    setOtherIsError(false);
    setUserWebSources([]);
  };

  const formatFileRefToFilename = async (article) => {
    // Retrieve the array of files from the API

    // Regex pattern to match file references within <a> tags
    const fileCitationRegex =
      /<a class=xpub_ref file_ref href='([^']+)' rel='noopener noreferrer' target='_blank'><sup>\[(\d+)\]<\/sup><\/a>/g;

    // Function to replace each <a> tag with a standalone <sup> showing the filename
    const replaceCallback = (match, fileId, fileIndex) => {
      // Find the corresponding file name for the given fileId
      const file = projectFiles.find((f) => f.id === fileId);
      const filename = file ? file.name : "unknown file";

      // Return the <sup> tag with a title attribute to show the filename as a tooltip
      return `<sup data-file-name="${filename}">[${fileIndex}]</sup>`;
    };

    // Replace all file references, stripping the <a> tags and adding tooltips to <sup>
    article = article.replace(fileCitationRegex, (match, fileId, fileIndex) =>
      replaceCallback(match, fileId, fileIndex)
    );

    return article;
  };

  async function uploadOutline() {
    try {
      const fileName = "objective.json";
      const data = JSON.stringify(objective);
      api.post(
        "file/uploadData",
        { data, fileName, owner: projectId, tag: "objective" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const generateSEOContent = async function (resources, style_files) {
    setShowErrorMsg(false);
    const outputFileName = generateFileName("draft.docx");
    onContentChange(outputFileName, "", tabIndex, false, "", []);
    setTabLoading(true, tabIndex);
    setShowMsg(true);
    setLoadingMsg(
      "Hang tight! We are crafting content according to your objective, input sources, and style. It should take no longer than 2-3 minutes."
    );

    // if we are coming from the create project page, the article will be generated for the first time
    if (objective.idea === undefined || objective.idea === "") {
      return;
    }

    uploadOutline();

    try {
      const requestData = {
        resources: resources,
        style_examples: style_files,
        idea: objective.idea.trim(),
        seo_keywords: seoKeywords,
      };

      const [textResult, imageResult] = await Promise.allSettled([
        api_external.post(env.salesPubAPI + "/text-gen-from-idea", requestData),
        api_external.post(
          env.salesPubAPI + "/image-gen-from-idea",
          {
            idea: objective.idea.trim(),
          },
          {
            responseType: "blob",
          }
        ),
      ]);

      // Handle text generation result
      if (textResult.status !== "fulfilled") {
        throw new Error(
          textResult.reason.response?.data?.error || "Text generation failed"
        );
      }

      const article = textResult.value.data.generatedContent[0].content;
      setMissedKeywords(textResult.value.data.missedKeywords);

      let base64Image = null;

      // Handle image generation result
      if (
        imageResult.status === "fulfilled" &&
        imageResult.value.status === 200
      ) {
        const imageBlob = new Blob([imageResult.value.data], {
          type: "image/jpeg",
        });
        base64Image = await blobToBase64(imageBlob);
      } else {
        console.warn("Image generation failed:", imageResult.reason);
      }

      // Log the usage
      api.post("/user/logAsyncServiceCall", {
        projectId: projectId,
        service_tag: "generateArticleWithStyle",
        service_parent_id: null,
        service_filename: null,
        options: {
          service_progress_status: false,
          service_payload: {
            input: {
              resources: resources,
              objective: objective,
              style_files: style_files,
            },
            output: {
              content: textResult.value.data.generatedContent[0].content,
              sources: textResult.value.data.sources,
              usage: textResult.value.data.usage,
            },
          },
        },
        logs: textResult.value.data.usage,
      });

      // Format file citations to have signed url
      let formatted_article = await formatFileRefToFilename(article);

      if (base64Image) {
        formatted_article = formatted_article.replace(
          /(<h1.*?>.*?<\/h1>)(.*?<\/p>)/,
          `$1$2<p><br><br></p><p><img src="${base64Image}" alt="Generated Image"/></p><p><br><br></p>`
        );
      }

      await setOutput(
        formatted_article,
        "article",
        outputFileName,
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        true,
        tabIndex
      );

      saveFile(
        formatted_article,
        outputFileName,
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "article"
      );
    } catch (error) {
      console.error("API error:", error);
      setShowErrorMsg(true);
      setErrorMsg(
        error.message
          ? error.message
          : "Error in generating SEO content. Please contact support@xpub.ai."
      );
    } finally {
      setShowMsg(false);
      setTabLoading(false, tabIndex);
    }
  };

  const extractDomain = (url) => {
    const domain = url.replace(/https?:\/\/(www\.)?/, "").split("/")[0];
    return domain;
  };

  // Function to check if a domain is in the preferred sources list
  const isPreferredSource = (url, preferredSourcesList) => {
    const domain = extractDomain(url);
    return preferredSourcesList.includes(domain);
  };

  return (
    <Modal
      open={isOpen}
      onRequestClose={onModalClose}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      onRequestSubmit={handleNextStep}
      onSecondarySubmit={handleSecondaryButtonClick}
      primaryButtonDisabled={
        isFetchingSources ||
        (step === 2
          ? !Object.values(selectedFileRoles).some((role) => role.selected)
          : false)
      }
      size="md"
      selectorPrimaryFocus="topic"
    >
      <div style={{ margin: "0 auto", width: "20rem" }}>
        <ProgressIndicator currentIndex={step - 1}>
          <ProgressStep
            label="Set objective"
            description="Set an objective for the draft"
          />
          <ProgressStep
            label="Pick sources"
            description="Select files and web sources for generating draft"
          />
        </ProgressIndicator>
      </div>

      {step === 1 && (
        <div style={{ margin: "5% 5% 0 5%" }}>
          <h3 style={{ fontSize: "var(--text-large)", fontWeight: "400" }}>
            Set the objective of the article
          </h3>
          <div style={{ marginTop: "2%" }}>
            <TextArea
              id="topic"
              labelText="Describe the idea for the draft"
              value={objective.idea}
              invalid={isTopicError}
              invalidText={topicErrorMsg}
              placeholder="e.g. How to Use Generative AI to Create Graphics"
              onChange={(e) => {
                setIsTopicError(false);
                setObjective({
                  ...objective,
                  idea: e.target.value,
                });
              }}
            />
            <br />
            <Dropdown
              style={{ marginTop: "2%" }}
              id="style-dropdown"
              invalid={isOtherError}
              titleText="Template"
              label="Select a template"
              items={styleProfiles.map((profile) => ({
                id: profile,
                label: profile,
              }))}
              itemToString={(item) => (item ? item.label : "")}
              selectedItem={
                styleProfiles
                  .map((profile) => ({
                    id: profile,
                    label: profile,
                  }))
                  .find((profile) => profile.id === objective.style) || null
              }
              onChange={handleStyleDropdownChange}
            />
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                // alignContent: "center",
                marginTop: "2%",
                gap: "10px",
              }}
            >
              <span
                style={{
                  display: "block",
                  fontSize: "var(--text-small)",
                  color: "#525252",
                }}
              >
                Enable web search for relevant reference information
              </span>
              <Toggle
                id="use-archives"
                toggled={doWebResearch}
                onToggle={(checked) => setDoWebResearch(checked)}
                hideLabel
              />
            </div>

            <div style={{ display: "flex", gap: "10px", marginTop: "2%" }}>
              <span
                style={{
                  display: "block",
                  fontSize: "var(--text-small)",
                  color: "#525252",
                }}
              >
                SEO keywords
              </span>
              <Tooltip
                direction="bottom-right"
                label="Enter your SEO keywords to be integrated in the output. Or alternatively, generate them based on your idea."
              >
                <Information style={{ fill: "#525252" }} />
              </Tooltip>
            </div>
            <KeywordTagInput
              currentSeoKeywords={seoKeywords}
              setSeoKeywords={(keywords) => setSEOKeywords(keywords)}
              addKeyWordToList={addPendingKeyword}
            />
            <br></br>
          </div>
        </div>
      )}

      {step === 2 && (
        <div style={{ margin: "5% 5% 0 5%" }}>
          {isFetchingSources ? (
            <GenerateWebLinksLoader />
          ) : (
            <div>
              <h4 style={{ fontSize: "var(--text-large)", fontWeight: "400" }}>
                Select files and web sources to generate a draft
              </h4>
              <div>
                <p
                  style={{
                    marginTop: "2%",
                    fontSize: "var(--text-medium)",
                    fontWeight: "400",
                    marginBottom: "2%",
                  }}
                >
                  Select up to 4 sources for generating draft
                </p>
                <div style={{ marginTop: "2%" }}>
                  {projectFiles.length > 0 &&
                    projectFiles.map((file, index) => (
                      <div
                        key={index}
                        className={`file-tile ${
                          selectedFileRoles[file.id]?.selected
                            ? "selected-tile"
                            : ""
                        }`}
                        onClick={() =>
                          handleTileClick(
                            file.name,
                            file.id,
                            "file",
                            file.contentType,
                            file.tag
                          )
                        }
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // padding: "10px",
                          // margin: "5px 0",
                          border: selectedFileRoles[file.id]?.selected
                            ? "2px solid purple"
                            : "2px solid #e0e0e0",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ marginRight: "10px" }}>
                            File source
                          </span>
                          <div
                            style={{
                              height: "20px",
                              width: "1px",
                              backgroundColor: "#e0e0e0",
                              marginRight: "10px",
                            }}
                          ></div>
                          <span>{file.name}</span>
                        </div>
                      </div>
                    ))}
                  {webSources.length > 0 &&
                    webSources.map((source, index) => (
                      <div
                        key={index}
                        className={`file-tile ${
                          selectedFileRoles[source.link]?.selected
                            ? "selected-tile"
                            : ""
                        }`}
                        onClick={() =>
                          handleTileClick(
                            source.title,
                            source.link,
                            "url",
                            source.mime,
                            []
                          )
                        }
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",

                          // padding: "10px",
                          // margin: "5px 0",
                          border: selectedFileRoles[source.link]?.selected
                            ? "2px solid purple"
                            : "2px solid #e0e0e0",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: "5px",
                          }}
                        >
                          <span style={{ marginRight: "10px" }}>
                            Web source
                          </span>
                          <div
                            style={{
                              height: "20px",
                              width: "1px",
                              backgroundColor: "#e0e0e0",
                              marginRight: "10px",
                            }}
                          ></div>
                          <div style={{ flex: 1, overflow: "hidden" }}>
                            <span
                              style={{
                                display: "block",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginBottom: "5px",
                              }}
                            >
                              {source.title}
                            </span>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "var(--text-medium)",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  // maxWidth: "40ch",
                                  color: "var(--xpub-brand-color)",
                                }}
                              >
                                {source.link.substring(0, 70) +
                                  (source.link.length > 70 ? "..." : "")}
                              </span>
                              <div>
                                {" "}
                                <div
                                  className="archive-button"
                                  style={{
                                    display: "flex",
                                    gap: "2px",
                                    marginBottom: "1%",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {/* {isPreferredSource(
                                    source.link,
                                    preferredSourcesList
                                  ) ? (
                                    <FavoriteFilled
                                      style={{
                                        marginLeft: "10px",
                                        padding: "0",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        fill: "red",
                                      }}
                                    />
                                  ) : (
                                    <button
                                      onClick={(e) => {
                                        updateFactcheckProfile(source.link);
                                      }}
                                      style={{
                                        marginLeft: "10px",
                                        padding: "0",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Favorite />
                                    </button>
                                  )} */}
                                  {/* <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigator.clipboard.writeText(
                                        source.link
                                      );
                                    }}
                                    style={{
                                      marginLeft: "10px",
                                      padding: "0",
                                      background: "none",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Copy />
                                  </button> */}
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      window.open(source.link, "_blank");
                                    }}
                                    style={{
                                      marginLeft: "10px",
                                      padding: "0",
                                      background: "none",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Launch />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {userWebSources.length > 0 &&
                    userWebSources.map((source, index) => (
                      <div
                        key={index}
                        className={`file-tile ${
                          selectedFileRoles[source.link]?.selected
                            ? "selected-tile"
                            : ""
                        }`}
                        onClick={() =>
                          handleTileClick(
                            source.title,
                            source.link,
                            "url",
                            source.mime,
                            []
                          )
                        }
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          border: selectedFileRoles[source.link]?.selected
                            ? "2px solid purple"
                            : "2px solid #e0e0e0",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: "5px",
                          }}
                        >
                          <span style={{ marginRight: "10px" }}>
                            Web source
                          </span>
                          <div
                            style={{
                              height: "20px",
                              width: "1px",
                              backgroundColor: "#e0e0e0",
                              marginRight: "10px",
                            }}
                          ></div>
                          <div style={{ flex: 1, overflow: "hidden" }}>
                            <span
                              style={{
                                display: "block",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginBottom: "5px",
                              }}
                            >
                              {source.title}
                            </span>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span
                                style={{
                                  fontSize: "var(--text-medium)",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  // maxWidth: "40ch",
                                  color: "var(--xpub-brand-color)",
                                }}
                              >
                                {source.link.substring(0, 70) +
                                  (source.link.length > 70 ? "..." : "")}
                              </span>
                              <div>
                                <div
                                  className="archive-button"
                                  style={{
                                    display: "flex",
                                    gap: "2px",
                                    marginBottom: "1%",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {isPreferredSource(
                                    source.link,
                                    preferredSourcesList
                                  ) ? (
                                    <FavoriteFilled
                                      style={{
                                        marginLeft: "10px",
                                        padding: "0",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        fill: "red",
                                      }}
                                    />
                                  ) : (
                                    <button
                                      title="Add domain to preferred research source list"
                                      onClick={(e) => {
                                        updateFactcheckProfile(source.link);
                                      }}
                                      style={{
                                        marginLeft: "10px",
                                        padding: "0",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Favorite />
                                    </button>
                                  )}
                                  {/* <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigator.clipboard.writeText(
                                        source.link
                                      );
                                    }}
                                    style={{
                                      marginLeft: "10px",
                                      padding: "0",
                                      background: "none",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Copy />
                                  </button> */}
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      window.open(source.link, "_blank");
                                    }}
                                    style={{
                                      marginLeft: "10px",
                                      padding: "0",
                                      background: "none",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Launch />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div
                    style={{
                      gap: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <AddWebSource
                      addValidatedWebSource={(source) => {
                        setUserWebSources([...userWebSources, source]);
                        setSelectedFileRoles({});
                      }}
                    />
                    <AddFileResource
                      projectId={projectId}
                      refreshFiles={fetchInputOutputFiles}
                      refreshEditorFiles={refreshFiles}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default IdeaGenerationModal;
