import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Paper,
  TextField,
  Modal,
  Box,
  Tooltip,
  Card,
  CardContent,
  Toolbar,
} from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import { api } from "../store";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";

const RFPDashboard = () => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteName, setDeleteName] = useState("");
  const [isDeleteError, setDeleteError] = useState(false);
  const [rfpProjects, setRFPProjects] = useState([]);
  const [selectedRowProject, setselectedRowProject] = useState("");
  const currentDate = new Date();
  const twoWeeksFromNow = new Date(
    currentDate.getTime() + 14 * 24 * 60 * 60 * 1000
  );
  const startOfWeek = new Date(
    currentDate.setDate(currentDate.getDate() - currentDate.getDay())
  );
  const endOfWeek = new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000);

  const isDateInCurrentWeek = (date) => {
    const d = new Date(date);
    return d >= startOfWeek && d <= endOfWeek;
  };

  const getProject = async () => {
    let response = await api.get("project/getRFXProjects");
    const rowsWithId = response.data.projects.map((row) => ({
      ...row,
      id: `${row._id}`,
      created: new Date(row.created),
      dueDate: row.dueDate ? new Date(row.dueDate) : null,
    }));
    setRFPProjects(rowsWithId);
  };

  useEffect(() => {
    getProject();
  }, []);

  const handleProjectLinkClick = (event, projectId, projectName) => {
    event.stopPropagation();
    const url = `/rfp-editor?projectId=${encodeURIComponent(
      projectId
    )}&projectName=${encodeURIComponent(projectName)}`;
    navigate(url);
  };

  const handleProjectDelete = async (event) => {
    event.preventDefault();
    if (deleteName !== selectedRow.name) {
      setDeleteError(true);
      return;
    }
    try {
      await api.delete(`project/deleteProject?projectId=${selectedRow.id}`);
      setDeleteModalState(false);
      getProject();
      setDeleteName("");
      setSelectedRow(null);
    } catch (error) {
      console.log("Error while deleting a project: " + error.message);
    }
  };

  const handleProjectMetadataChange = async (
    id,
    startDate,
    dueDate,
    status
  ) => {
    try {
      await api.put(`project/updateProjectMetadata`, {
        projectId: id,
        startDate: startDate,
        dueDate: dueDate,
        status: status,
      });
      getProject(); // Refresh the projects after updating
    } catch (error) {
      console.log("Error while updating project status: " + error.message);
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };

    handleProjectMetadataChange(
      newRow.id,
      newRow.created instanceof Date ? newRow.created.toISOString() : null,
      newRow.dueDate instanceof Date ? newRow.dueDate.toISOString() : null,
      newRow.status
    );
    return updatedRow;
  };

  const columns = [
    {
      field: "name",
      headerName: "RFX Title",
      flex: 1,
      width: 250,
      renderCell: (params) => (
        <Typography
          component="a"
          sx={{ cursor: "pointer", color: "primary.main" }}
          onClick={(event) =>
            handleProjectLinkClick(event, params.row.id, params.row.name)
          }
        >
          {params.value}
        </Typography>
      ),
    },
    { field: "client", headerName: "Client", flex: 1, width: 200 },
    {
      field: "created",
      headerName: "Start Date",
      type: "date",
      editable: true,
      width: 200,
      // valueGetter: (params) => params ? new Date(params.value) : null,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      type: "date",
      editable: true,
      width: 200,
      // valueGetter: (params) => params ? new Date(params.value) : null,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: ["In Progress", "Completed"],
      width: 200,
    },
  ];

  const CustomToolbar = ({ selectedRow, onCancelSelection, onDelete }) => {
    return (
      <GridToolbarContainer
        sx={{ height: "4rem", border: "var(--DataGrid-rowBorderColor)" }}
      >
        <Toolbar
          sx={{
            width: "100%",
            justifyContent: "flex-end",
            padding: "8px",
          }}
        >
          {selectedRow ? (
            <>
              <Tooltip title="Cancel Selection">
                <Button
                  startIcon={<CancelIcon />}
                  onClick={onCancelSelection}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
              </Tooltip>
              <Tooltip title="Delete Selected Row">
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={onDelete}
                  color="error"
                >
                  Delete
                </Button>
              </Tooltip>
            </>
          ) : (
            <Box sx={{ visibility: "hidden" }}>Placeholder</Box>
          )}
        </Toolbar>
      </GridToolbarContainer>
    );
  };

  const StatCard = ({ title, value, subText }) => (
    <Card
      sx={{
        width: 180,
        height: 180,
        borderRadius: "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        // background: "#f0f0f0",
        border: "3px solid #5a0b4d",
        boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
        transition: "all 0.3s ease",
        "&:hover": {
          transform: "translateY(-10px)",
        },
      }}
    >
      <CardContent
        sx={{
          padding: 0,
          textAlign: "center",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="sectionHeading"
          component="div"
          gutterBottom
          color="primary"
        >
          {title}
        </Typography>
        <Typography
          variant="pageHeading"
          color="secondary"
          component="div"
          sx={{ my: 1, fontWeight: "bold" }}
        >
          {value}
        </Typography>
        <Typography variant="primaryText" color="text.secondary">
          {subText}
        </Typography>
      </CardContent>
    </Card>
  );

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: "none",
    "& .MuiDataGrid-cell": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      borderBottom: `2px solid ${theme.palette.divider}`,
    },
    "& .MuiDataGrid-virtualScroller": {
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: `2px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.default,
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  return (
    <Box sx={{ margin: "1.5rem 2rem" }}>
      <Modal
        open={isDeleteModalOpen}
        onClose={() => setDeleteModalState(false)}
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            variant="sectionHeading"
            sx={{ color: "black", mb: "0.5rem" }}
            component="div"
          >
            Confirm delete project
          </Typography>
          <Typography variant="primaryText" sx={{ color: "black" }}>
            To delete the project, type the project name: {selectedRow?.name}
          </Typography>
          <TextField
            fullWidth
            value={deleteName}
            onChange={(e) => {
              setDeleteError(false);
              setDeleteName(e.target.value);
            }}
            error={isDeleteError}
            helperText={
              isDeleteError
                ? "Value cannot be empty or does not match the project name"
                : ""
            }
            margin="normal"
          />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setDeleteModalState(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              onClick={handleProjectDelete}
              variant="contained"
              color="error"
            >
              Delete
            </Button>
          </Box>
        </Paper>
      </Modal>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 6,
        }}
      >
        <Typography variant="pageHeading">Projects</Typography>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
          onClick={() => navigate("/new-rfp")}
        >
          Create
        </Button>
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          mb: 5,
          width: "90%",
          mx: "auto",
        }}
      >
        <Grid item xs={12} sm={4} display="flex" justifyContent="center">
          <StatCard
            title="Active RFXs"
            value={
              rfpProjects.filter((project) => project.status === "In Progress")
                .length
            }
            subText={`+${
              rfpProjects.filter((project) =>
                isDateInCurrentWeek(project.created)
              ).length
            } from this week`}
          />
        </Grid>
        <Grid item xs={12} sm={4} display="flex" justifyContent="center">
          <StatCard
            title="Due Soon"
            value={
              rfpProjects.filter(
                (project) =>
                  new Date(project.dueDate) <= twoWeeksFromNow &&
                  new Date(project.dueDate) >= currentDate
              ).length
            }
            subText="in two weeks"
          />
        </Grid>
        <Grid item xs={12} sm={4} display="flex" justifyContent="center">
          <StatCard
            title="Completed"
            value={
              rfpProjects.filter((project) => project.status === "Completed")
                .length
            }
            subText={`+${
              rfpProjects.filter((project) =>
                isDateInCurrentWeek(project.completeDate)
              ).length
            } from last week`}
          />
        </Grid>
      </Grid>

      <Box sx={{ width: "90%", mb: 2, mx: "auto" }}>
        <Box style={{ height: 440, width: "100%", marginTop: "16px" }}>
          <StyledDataGrid
            rows={rfpProjects}
            columns={columns}
            autoPageSize
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRow ? [selectedRow.id] : []}
            onRowSelectionModelChange={(newSelectionModel) => {
              const selectedId = newSelectionModel[0];
              const selectedRow = rfpProjects.find(
                (row) => row.id === selectedId
              );
              setSelectedRow(selectedRow || null);
            }}
            disableMultipleRowSelection={true}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => {
              console.log(
                "Error while processing row update: " + error.message
              );
            }}
            slots={{
              toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                selectedRow,
                onCancelSelection: () => setSelectedRow(null),
                onDelete: () => setDeleteModalState(true),
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RFPDashboard;
