import React, { useState, useEffect } from "react";
import WordPressCMS from "./WordpressCMS";
import "./CMSTiles.scss";
import CMSItemTile from "./CMSItemTile";
import { Grid, Column, Row, InlineNotification } from "@carbon/react";
import { convertArchiveTypes, supportedCMS } from "../../util";
import SharePointConfig from "../SharepointConfig";

const CMSTiles = ({ setArchiveonfiguration, showCMS, noArchives }) => {
  const [selectedCMS, setSelectedCMS] = useState(null);
  const [showUploadSuccess, setUploadConfigured] = useState(false);
  const [isSharePointAuthTriggered, setIsSharePointAuthTriggered] =
    useState(false);

  useEffect(() => {
    if (selectedCMS === "sharePoint") {
      setIsSharePointAuthTriggered(true);
    }
  }, [selectedCMS, showCMS]);

  const onClickCancel = () => {
    setSelectedCMS("");
    setIsSharePointAuthTriggered(false);
  };

  const toggleUploadConfigured = (val) => {
    setUploadConfigured(val);
  };

  return (
    <div>
      {selectedCMS === "wordPress" || selectedCMS === "drupal" ? (
        <WordPressCMS
          onCancel={onClickCancel}
          setArchiveonfiguration={setArchiveonfiguration}
          cmsName={selectedCMS}
          apiPath={
            supportedCMS.filter((cms) => cms.archiveType === selectedCMS)[0]
              ?.apiPath || null
          }
        />
      ) : selectedCMS === "sharePoint" ? (
        <SharePointConfig isAuthTriggered={isSharePointAuthTriggered} />
      ) : (
        <div style={{ margin: "1.5rem 2rem" }}>
          <div className="page-header">
            <div className="page-title-container">
              <span className="page-title" style={{ marginTop: "0.7rem" }}>
                {noArchives ? "Configure Archives" : "Add Archive"}
              </span>
            </div>
            <div className="page-support-text-container">
              <p>Select a CMS to start archives configuration</p>
            </div>
          </div>
          {showUploadSuccess && (
            <InlineNotification
              lowContrast
              kind="success"
              title="Upload Files Configured"
              subtitle="Your upload files have been successfully configured."
              hideCloseButton={true}
              style={{ marginBottom: "5%" }}
            />
          )}
          <Grid
            fullWidth
            style={{
              height: "70vh",
              overflowY: "auto",
              gap: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Column
              lg={16}
              md={8}
              sm={4}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {showCMS.map((cms, index) => (
                <Column key={index} lg={4} md={4} sm={4}>
                  <CMSItemTile
                    cms={cms}
                    onSelectCMS={setSelectedCMS}
                    setArchiveonfiguration={setArchiveonfiguration}
                    setUploadConfigured={toggleUploadConfigured}
                  />
                </Column>
              ))}
            </Column>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default CMSTiles;
