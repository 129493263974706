import React, { useState, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Button,
  ListItemIcon,
  Modal,
  Paper,
  TextareaAutosize,
  Tooltip,
  Badge,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { useSelector, useDispatch } from "react-redux";
import {
  addItem,
  reorderItems,
  deleteItemAndUpdate,
  setItems,
} from "../../reduxstore/itemsSlice";
import {
  addItemStatus,
  updateItemStatus,
} from "../../reduxstore/itemsStatusSlice";
import { api, api_external } from "../../store";
import env from "../../config";
import { v4 as uuidv4 } from "uuid";

const EditableItem = React.memo(
  ({
    item,
    index,
    editMode,
    onItemClick,
    onDeleteItem,
    onDragStart,
    onDragOver,
    onDrop,
    onDragEnd,
    isDraggedOver,
    isLoading,
    isError,
    isSelected,
    todoCount,
  }) => {
    const itemRef = useRef(null);

    return (
      <ListItem
        ref={itemRef}
        draggable={editMode}
        onDragStart={(e) => onDragStart(e, index)}
        onDragOver={(e) => onDragOver(e, index)}
        onDrop={(e) => onDrop(e, index)}
        onDragEnd={onDragEnd}
        sx={{
          borderRadius: "8px",
          borderTopLeftRadius: isDraggedOver ? "0" : "8px",
          borderTopRightRadius: isDraggedOver ? "0" : "8px",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          backgroundColor: isSelected ? "rgba(0, 0, 0, 0.06)" : "none",
          marginBottom: "8px",
          cursor: editMode ? "move" : "pointer",
          borderTop: isDraggedOver ? "3px solid #719fa8" : "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
            flexGrow: 1,
            minWidth: 0,
          }}
        >
          {editMode && (
            <ListItemIcon sx={{ minWidth: "24px", marginRight: "8px" }}>
              <DragIndicatorIcon />
            </ListItemIcon>
          )}
          <ListItemText
            primary={item.section_title}
            onClick={() => onItemClick(item)}
            primaryTypographyProps={{
              sx: {
                fontSize: "0.875rem", // Sets the font size of the primary text
                lineHeight: 1.2,
                color: "#757575",
                wordBreak: "break-word",
                overflowWrap: "break-word",
              },
            }}
            sx={{
              cursor: "pointer",
              flexGrow: 1,
              minWidth: 0,
            }}
          />
          {!editMode && !isLoading && todoCount > 0 && (
            <Badge
              title="TODOs"
              badgeContent={todoCount}
              sx={{
                "& .MuiBadge-badge": {
                  color: "#55828b",
                  border: "0.1rem solid #55828b",

                  // backgroundColor: '#8db2b9',
                  // color: "white",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                },
                "& .css-ywgamk-MuiBadge-badge": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "0.1rem",

                }
              }}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
          {isLoading && (
            <CircularProgress
              size={16}
              color="secondary"
              sx={{ marginRight: "8px" }}
            />
          )}
          {isError && (
            <ErrorRoundedIcon
              sx={{ marginRight: "8px", color: "red", fontSize: "16px" }}
            />
          )}
          {editMode && (
            <IconButton
              onClick={() => onDeleteItem(item)}
              size="small"
              sx={{ padding: "4px" }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </ListItem>
    );
  }
);

const DraftLeftPanelComponent = ({
  onItemClick,
  projectId,
  archiveIds,
  refreshItems,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const listRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newSectionTitle, setNewSectionTitle] = useState("");
  const [newSectionDescription, setNewSectionDescription] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const items = useSelector((state) => state.items);
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.selectedItem);
  const itemsStatus = useSelector((state) => state.itemsStatus);

  const handleDeleteItem = useCallback(
    async (itemToDelete) => {
      const originalItems = [...items];
      const updatedItems = items.filter(
        (item) => item.uuid !== itemToDelete.uuid
      );

      // Optimistically update the UI
      dispatch(setItems(updatedItems));

      try {
        await dispatch(
          deleteItemAndUpdate({ itemToDelete, projectId })
        ).unwrap();
      } catch (error) {
        console.error("Error deleting item:", error);
        // Revert the change if the operation fails
        dispatch(setItems(originalItems));
        setSnackbarMessage("Failed to delete item. Please try again.");
        setSnackbarOpen(true);
      }
    },
    [items, dispatch, projectId]
  );

  const handleReorderItems = useCallback(
    async (newOrder) => {
      const originalItems = [...items];

      // Optimistically update the UI
      dispatch(setItems(newOrder));

      try {
        await dispatch(reorderItems({ newOrder, projectId })).unwrap();
      } catch (error) {
        console.error("Error reordering items:", error);
        // Revert the change if the operation fails
        dispatch(setItems(originalItems));
        setSnackbarMessage("Failed to reorder items. Please try again.");
        setSnackbarOpen(true);
      }
    },
    [items, dispatch, projectId]
  );

  const handleAddItem = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewSectionTitle("");
    setNewSectionDescription("");
  };

  const handleGenerateSection = async () => {
    handleCloseModal();
    // Keep edit mode as it is, do not disable it here
    const id = uuidv4();
    const newItem = {
      uuid: id,
      section_title: newSectionTitle,
    };

    // Optimistically add the new item
    dispatch(addItem(newItem));
    dispatch(addItemStatus({ uuid: id, isGenerating: true }));

    try {
      const requestData = {
        project_id: projectId,
        section_id: id,
        section_title: newSectionTitle,
        section_desc: newSectionDescription,
        archive_ids: archiveIds,
      };

      await api_external.post(
        `${env.salesPubAPI}/generate-rfx-section-response`,
        requestData
      );

      dispatch(updateItemStatus({ uuid: id, isGenerating: false }));
      refreshItems();
    } catch (error) {
      console.error("Error generating section:", error);
      dispatch(setItems(items.filter((item) => item.uuid !== id)));
      setSnackbarMessage("Failed to generate section. Please try again.");
      setSnackbarOpen(true);
    }
  };

  // Ensure that editMode is not changed during drag & drop
  const onDragStart = useCallback(
    (e, index) => {
      setDraggedItem(items[index]);
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/plain", index);
    },
    [items]
  );

  const onDragOver = useCallback((e, index) => {
    e.preventDefault();
    setDragOverIndex(index);
  }, []);

  const onDragEnd = useCallback(() => {
    setDraggedItem(null);
    setDragOverIndex(null);
  }, []);

  const onDrop = useCallback(
    (e, droppedIndex) => {
      e.preventDefault();
      const draggedIndex = Number(e.dataTransfer.getData("text/plain"));

      if (draggedIndex === droppedIndex) return;

      const updatedItems = [...items];
      const [reorderedItem] = updatedItems.splice(draggedIndex, 1);
      updatedItems.splice(droppedIndex, 0, reorderedItem);

      handleReorderItems(updatedItems);
    },
    [items, handleReorderItems]
  );

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          borderBottom: "1px solid #ddd",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="sectionHeading"
            sx={{ marginRight: "8px", color: "black" }}
          >
            Sections
          </Typography>
          {editMode && (
            <Tooltip title="Drag sections to reorder" arrow>
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                (Drag to reorder sections)
              </Typography>
            </Tooltip>
          )}
        </Box>
        <IconButton onClick={() => setEditMode(!editMode)} size="small">
          {editMode ? (
            <CancelIcon fontSize="small" />
          ) : (
            <EditIcon fontSize="small" />
          )}
        </IconButton>
      </Box>
      <List ref={listRef} sx={{ flexGrow: 1, overflowY: "auto", padding: 0 }}>
        {items.map((item, index) => {
          const isLoading =
            itemsStatus.find((status) => status.uuid === item.uuid)
              ?.isGenerating || false;
          const isError =
            itemsStatus.find((status) => status.uuid === item.uuid)?.isError ||
            false;
          const todoCount =
            itemsStatus.find((status) => status.uuid === item.uuid)
              ?.todoCount || 0;

          return (
            <EditableItem
              key={item.uuid}
              item={item}
              index={index}
              editMode={editMode}
              onItemClick={onItemClick}
              onDeleteItem={handleDeleteItem}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onDragEnd={onDragEnd}
              isDraggedOver={dragOverIndex === index}
              isLoading={isLoading}
              isError={isError}
              isSelected={item.uuid === selectedItem?.uuid}
              todoCount={todoCount}
            />
          );
        })}
      </List>
      {editMode && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddItem}
          fullWidth
          sx={{ mt: 2, textTransform: "none" }}
        >
          Add New Section
        </Button>
      )}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="add-section-modal"
        aria-describedby="modal-to-add-new-section"
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="sectionHeading" sx={{ color: "black" }}>
            Add new section
          </Typography>
          <TextField
            label="Section Title"
            value={newSectionTitle}
            onChange={(e) => setNewSectionTitle(e.target.value)}
            fullWidth
            required
          />
          <TextareaAutosize
            minRows={3}
            placeholder="Section Description"
            value={newSectionDescription}
            onChange={(e) => setNewSectionDescription(e.target.value)}
            style={{ width: "100%", padding: "8px" }}
            required
          />
          <Button
            variant="contained"
            onClick={handleGenerateSection}
            sx={{
              textTransform: "none",
            }}
          >
            Generate
          </Button>
        </Paper>
      </Modal>
    </Box>
  );
};

export default DraftLeftPanelComponent;
