import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Column,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  TextInput,
} from "@carbon/react";
import {
  List,
  Grid as TileIcon,
  FolderAdd,
  DocumentAdd,
} from "@carbon/icons-react";
import FolderIcon from "@mui/icons-material/Folder";

const FileManager = ({
  uploadFile,
  setFolderId,
  createFolder,
  currentFolderContents,
  handleArchiveFileClick,
  deleteFile,
}) => {
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentFolder, setCurrentFolder] = useState("");
  const [newFolderName, setNewFolderName] = useState("");
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [view, setView] = useState("tile"); // tile or list
  const [clickTimeout, setClickTimeout] = useState(null);
  const fileManagerRef = useRef(null); // Ref to the FileManager container
  const [path, setPath] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);

  // Event handler to unselect all items
  const handleClickOutside = (event) => {
    if (
      fileManagerRef.current &&
      !fileManagerRef.current.contains(event.target)
    ) {
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("click", handleClickOutside);

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log("THis changing");
    if (currentFolderContents) {
      const filteredFolders = currentFolderContents.filter(
        (item) => item.isDir
      );
      const filteredFiles = currentFolderContents.filter((item) => !item.isDir);
      console.log(filteredFiles);

      setFolders(filteredFolders);
      setFiles(filteredFiles);
    }
  }, [currentFolderContents]);

  const handleCreateFolder = () => {
    if (newFolderName) {
      createFolder(newFolderName);
      setFolders([
        ...folders,
        { name: newFolderName, dateModified: new Date().toLocaleString() },
      ]);
      setNewFolderName("");
      setIsCreatingFolder(false);
    }
  };

  const handleDeleteItems = () => {
    setFolders(
      folders.filter((folder) => !selectedItems.includes(folder.name))
    );
    setFiles(files.filter((file) => !selectedItems.includes(file.name)));
    setSelectedItems([]);
  };

  const handleSelectItem = (itemName, event) => {
    if (event.shiftKey) {
      // Allow multi-selection
      setSelectedItems(
        (prevSelected) =>
          prevSelected.includes(itemName)
            ? prevSelected.filter((i) => i !== itemName) // Deselect if already selected
            : [...prevSelected, itemName] // Add to selection if not selected
      );
    } else {
      // Single selection
      setSelectedItems([itemName]);
    }
  };

  const handleFolderClick = (folderName, folderId) => {
    setPath([...path, { name: folderName, id: folderId }]);
    setFolderId(folderId);
    setSelectedItems([]);
  };

  const handleBreadcrumbClick = (index) => {
    const newPath = path.slice(0, index + 1);
    const selectedFolder = newPath[newPath.length - 1];

    // Check if the selected folder is undefined, indicating the root folder
    const folderId = selectedFolder ? selectedFolder.id : null;

    setPath(newPath); // Update the path to the selected breadcrumb
    setFolderId(folderId); // Set the folderId, or null if it's the root folder
  };

  const toggleView = (view) => {
    setView(view);
  };

  const handleItemClick = (itemName, itemId, isFolder, event) => {
    console.log(event);
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      // Double-click detected; open the folder or file
      if (isFolder) {
        handleFolderClick(itemName, itemId);
      } else {
        handleArchiveFileClick(itemId);
      }
    } else {
      // Single click; set selected
      handleSelectItem(itemName, event);
      setClickTimeout(
        setTimeout(() => {
          setClickTimeout(null);
        }, 250) // Adjust delay as needed
      );
    }
  };

  const handleRightClick = (event, itemName, itemId, isFolder) => {
    event.preventDefault();
    if (!isFolder) {
      setContextMenu({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
        itemName,
        itemId,
      });
    }
  };

  return (
    <>
      <Grid>
        <Column lg={16}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "2rem",
            }}
          >
            <div>
              <Breadcrumb>
                <BreadcrumbItem onClick={() => handleBreadcrumbClick(-1)}>
                  archives
                </BreadcrumbItem>
                {path.map((folder, index) => (
                  <BreadcrumbItem
                    key={folder.id}
                    isCurrentPage={index === path.length - 1}
                    onClick={() => handleBreadcrumbClick(index)}
                  >
                    {folder.name}
                  </BreadcrumbItem>
                ))}
              </Breadcrumb>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                  gap: "1rem",
                }}
              >
                {/* <List
                  size="24"
                  onClick={() => toggleView("list")}
                  style={{ cursor: "pointer" }}
                />
                <TileIcon
                  size="24"
                  onClick={() => toggleView("tile")}
                  style={{ cursor: "pointer" }}
                /> */}
                <FolderAdd
                  size="24"
                  onClick={() => setIsCreatingFolder(true)}
                  style={{ cursor: "pointer" }}
                />
                <DocumentAdd
                  size="24"
                  onClick={uploadFile}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
        </Column>
      </Grid>
      <Grid>
        <Column lg={16}>
          {view === "list" ? (
            <>
              <div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    padding: "1rem",
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontSize: "var(--text-medium)",
                        fontWeight: "550",
                      }}
                    >
                      Name
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        fontSize: "var(--text-medium)",
                        fontWeight: "550",
                      }}
                    >
                      Date Modified
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        fontSize: "var(--text-medium)",
                        fontWeight: "550",
                      }}
                    >
                      Type
                    </span>
                  </div>
                </div>
                {folders.map((folder, index) => (
                  <div
                    key={folder.name}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      alignItems: "center",
                      padding: "0.5rem",
                      background: selectedItems.includes(folder.name)
                        ? "var(--contrast-color-secondary)"
                        : index % 2 === 0
                        ? "lightgray"
                        : "none", // Alternate rows gray
                      cursor: "pointer",
                      fontSize: "var(--text-medium)",
                      borderRadius: "var(--border-radius)",
                    }}
                    onClick={() =>
                      handleItemClick(folder.name, file.id, true, event)
                    }
                    onContextMenu={(e) => handleRightClick(e, folder.name)}
                  >
                    <div>{folder.name}</div>
                    <div>{folder.dateModified}</div>
                    <div>{folder.type}</div>
                  </div>
                ))}
                {files.map((file, index) => (
                  <div
                    key={file.name}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      alignItems: "center",
                      padding: "0.5rem",
                      background: selectedItems.includes(file.name)
                        ? "var(--contrast-color-secondary)"
                        : index % 2 === 0
                        ? "lightgray"
                        : "none", // Alternate rows gray
                      cursor: "pointer",
                      fontSize: "var(--text-medium)",
                      borderRadius: "var(--border-radius)",
                    }}
                    onClick={() =>
                      handleItemClick(file.name, file.id, false, event)
                    }
                    onContextMenu={(e) => handleRightClick(e, file.name)}
                  >
                    <div>{file.name}</div>
                    <div>{file.dateModified}</div>
                    <div>{file.type}</div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {folders.map((folder) => (
                <div
                  key={folder.name}
                  onClick={(event) =>
                    handleItemClick(folder.name, folder.id, true, event)
                  } // Pass event
                  style={{
                    margin: "0.5rem",
                    padding: "0.5rem",
                    textAlign: "center",
                    cursor: "pointer",
                    border: "none",
                    boxShadow: "none",
                    width: "5rem",
                  }}
                >
                  <div
                    style={{
                      fontSize: "3rem",
                      marginBottom: "0.5rem",
                      background: selectedItems.includes(folder.name)
                        ? "var(--contrast-color-secondary)"
                        : "none",
                      borderRadius: "var(--border-radius)",
                      padding: "0.5rem",
                    }}
                  >
                    <FolderIcon fontSize="large" />
                  </div>
                  <div
                    title={folder.name}
                    style={{
                      fontSize: "var(--text-medium)",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {folder.name}
                  </div>
                </div>
              ))}

              {files.map((file) => (
                <div
                  key={file.name}
                  onClick={(event) =>
                    handleItemClick(file.name, file.id, false, event)
                  } // Pass event
                  style={{
                    margin: "0.2rem",
                    textAlign: "center",
                    cursor: "pointer",
                    width: "10rem",
                  }}
                >
                  <div
                    style={{
                      fontSize: "3rem",
                      marginBottom: "0.5rem",
                      background: selectedItems.includes(file.name)
                        ? "var(--contrast-color-secondary)"
                        : "none",
                      borderRadius: "var(--border-radius)",
                    }}
                  >
                    {file.contentType === "application/pdf" ? "📄" : "📄"}
                  </div>
                  <div
                    title={file.name}
                    style={{
                      fontSize: "var(--text-medium)",
                      width: "100%",
                      whiteSpace: "normal",
                      overflow: "visible",
                      // textOverflow: "ellipsis",
                    }}
                  >
                    {file.name}
                  </div>
                </div>
              ))}
            </div>
          )}
        </Column>
      </Grid>

      {/* Modal for creating a new folder */}
      {isCreatingFolder && (
        <Modal
          open={isCreatingFolder}
          modalHeading="Create New Folder"
          primaryButtonText="Create"
          secondaryButtonText="Cancel"
          onRequestClose={() => setIsCreatingFolder(false)}
          onRequestSubmit={handleCreateFolder}
        >
          <TextInput
            id="new-folder-name"
            labelText="Folder Name"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
          />
        </Modal>
      )}
      {contextMenu && (
        <div
          style={{
            position: "fixed",
            top: contextMenu.mouseY,
            left: contextMenu.mouseX,
            backgroundColor: "white",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            borderRadius: "4px",
            padding: "8px",
            zIndex: 1000,
          }}
        >
          <button onClick={deleteFile}>Delete</button>
        </div>
      )}
    </>
  );
};

export default FileManager;
