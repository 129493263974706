// src/store/itemsStatusSlice.js
import { createSlice } from "@reduxjs/toolkit";

const itemsStatusSlice = createSlice({
  name: "itemsStatus",
  initialState: [], // Start with an empty array
  reducers: {
    initializeItemsStatus: (state, action) => {
      // This action will be used to set the initial array of item statuses
      return action.payload.map((item) => ({
        uuid: item.uuid,
        isGenerating: false,
        isError: false,
        todoCount: item.todoCount,
      }));
    },
    updateItemStatus: (state, action) => {
      const { uuid, ...updates } = action.payload;
      const itemIndex = state.findIndex((item) => item.uuid === uuid);
      if (itemIndex !== -1) {
        state[itemIndex] = { ...state[itemIndex], ...updates };
      }
    },
    addItemStatus: (state, action) => {
      state.push({
        uuid: action.payload.uuid,
        isGenerating: false,
        isError: false,
        ...action.payload,
      });
    },
  },
});

export const { initializeItemsStatus, updateItemStatus, addItemStatus } =
  itemsStatusSlice.actions;
export default itemsStatusSlice.reducer;
