import React, { useRef, useImperativeHandle, forwardRef } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";

const PDFViewer = forwardRef(({ fileUrl, initialPage }, ref) => {
  const viewerRef = useRef(null);
  const currentPageRef = useRef(initialPage);

  useImperativeHandle(ref, () => ({
    getCurrentPage: () => currentPageRef.current,
  }));

  const handlePageChange = (e) => {
    currentPageRef.current = e.currentPage;
  };

  const pageLayout = {
    transformSize: ({ size }) => ({
      height: size.height + 10,
      width: size.width + 10,
    }),
  };

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  const pageNavigationPluginInstance = pageNavigationPlugin();

  const transform = (slot) => ({
    ...slot,
    // These slots will be empty
    Download: () => <></>,
    Open: () => <></>,
    // EnterFullScreen: () => <></>,
    SwitchTheme: () => <></>,
  });

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "var(--border-radius)",
        margin: "0rem 0.3rem 0.3rem 0.3rem",
        // border: "0.1rem solid rgb(221, 219, 219)",
        height: "calc(100vh - 3.5rem)",
      }}
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
        <div
          style={{
            height: "87vh",
            overflow: "auto",
            borderRadius: "var(--border-radius)",
          }}
        >
          <Viewer
            ref={viewerRef}
            plugins={[toolbarPluginInstance, pageNavigationPluginInstance]}
            fileUrl={fileUrl}
            defaultScale={1.1}
            pageLayout={pageLayout}
            onPageChange={handlePageChange}
            initialPage={initialPage}
          />
        </div>
      </Worker>
    </div>
  );
});

export default PDFViewer;

// const highlightPluginInstance = highlightPlugin({
//   renderHighlightTarget: (props) => (
//     <div
//       style={{
//         borderRadius: "var(--border-radius)",
//         padding: "0.5rem 1rem",
//         background: "#eee",
//         display: "flex",
//         gap: "0.5rem",
//         flexDirection: "column",
//         position: "absolute",
//         left: `${props.selectionRegion.left}%`,
//         top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
//         transform: "translate(0, 8px)",
//         zIndex: 1,
//         boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
//         fontSize: "var(--text-medium)",
//       }}
//     >
//       <button
//         style={{
//           display: "flex",
//           alignItems: "center",
//           gap: "0.5rem",
//           color: "var(--element-hover-color)",
//           fontSize: "var(--text-medium)",
//         }}
//         onClick={() => {
//           props.toggle();
//           onAnswer(props.selectedText);
//         }}
//       >
//         <QuestionAnswering />
//         Answer
//       </button>

//       <Divider />
//       <button
//         style={{
//           display: "flex",
//           alignItems: "center",
//           gap: "0.5rem",
//           color: "var(--element-hover-color)",
//           fontSize: "var(--text-medium)",
//         }}
//         onClick={() => {
//           props.toggle();
//           onVerify(props.selectedText);
//         }}
//       >
//         <Checkmark />
//         Verify
//       </button>
//     </div>
//   ),
// });
