import React from "react";
import { Grid, Skeleton } from "@mui/material";

const RFPChecklistSkeleton = () => {
  return (
    <Grid
      container
      spacing={2}
      style={{
        padding: "0px 1rem",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Grid item xs={12} md={3}>
        <Skeleton
          animation="wave"
          variant="rounded"
          width="100%"
          height="40%"
          style={{ marginBottom: "16px" }}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="100%"
          height="55%"
          style={{ marginBottom: "16px" }}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Skeleton
          animation="wave"
          variant="rounded"
          width="100%"
          height="30%"
          style={{ marginBottom: "16px" }}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="100%"
          height="35%"
          style={{ marginBottom: "16px" }}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="100%"
          height="25%"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Skeleton
          animation="wave"
          variant="rounded"
          width="100%"
          height="60%"
          style={{ marginBottom: "16px" }}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="100%"
          height="30%"
        />
      </Grid>
    </Grid>
  );
};

export default RFPChecklistSkeleton;
