import React, { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { InlineLoading, InlineNotification, IconButton } from "@carbon/react";
import { Download, Save } from "@carbon/icons-react";
import "./QuillEditor.scss";
import { api, api_external } from "../store";
import CustomMenu from "./CustomMenu";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import { FileExtensionMimeTypes } from "../util";
import GenerationModal from "./editor/GenerationModal";
import TranslationModal from "./editor/TranslationModal";
import ReactDOM from "react-dom";
import env from "../config";
import logo_slash from "../Assets/slash.png";
import AudioPlayer from "./AudioPlayer";
// import IdeaGenerationModal from "./editor/IdeaGenerationModal";
import BlogGenerationModal from "./editor/BlogGenerationModal";

const MAX_FACTCHECK_SIZE = 35;

const QuillEditor = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [isOutlineLoading, setOutlineLoading] = useState(false);
  const [isArticleLoading, setArticleLoading] = useState(false);
  const [isSummaryLoading, setSummaryLoading] = useState(false);
  const [isGeneratingPodcast, setGenPodcast] = useState(false);
  const [missedKeywords, setMissedKeywords] = useState([]);
  const [enableArticleGeneration, setEnableArticleGen] = useState(
    props.tabName === "outline.txt"
  );
  const [outline, setOutline] = useState("");
  const [isError, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [summarizeOptions, setSummarizeOptions] = useState(false);
  const [isGenModalOpen, setGenModalOpen] = useState(false);
  const [isTranslationModalOpen, setTranslationModalOpen] = useState(false);
  const [fileIdsForGen, setFileIdsForGen] = useState([]);
  const [objective, setObjective] = useState({});
  const [factCheckCSS, setFactCheckCSS] = useState({});
  const [isDraft, setIsDraft] = useState(false);
  const [refreshGenModal, setRefreshGenModal] = useState(false);
  const [translateFullText, setTranslateFullText] = useState(false);
  const selectedTextRef = useRef("{}");
  const [audioStartTime, setAudioStartTime] = useState(0.0);
  const [thresholdStatus, setThresholdStatus] = useState(props.thresholdStatus);
  const [confidenceThreshold, setConfidenceThreshold] = useState(
    props.thresholdValue
  );

  const [isIdeaGenModalOpen, setIdeaGenModalOpen] = useState(false);
  const [refreshIdeaGenModal, setRefreshIdeaGenModal] = useState(false);

  const [isBlogGenModalOpen, setBlogGenModalOpen] = useState(false);
  const [refreshBlogGenModal, setRefreshBlogGenModal] = useState(false);
  const [changeDetected, setChangeDetected] = useState(false);
  const magicButtonRef = useRef(null);
  const customMenuRef = useRef(null);

  let wordCount = 0;

  function applyDeltaToOriginalText(originalText, delta) {
    let updatedText = originalText;
    let offset = 0;

    delta.ops.forEach((op) => {
      if (op.retain) {
        offset += op.retain;
      } else if (op.insert) {
        let position = calculateActualPosition(updatedText, offset);
        updatedText = insertText(updatedText, op.insert, position);
        offset += op.insert.length;
      } else if (op.delete) {
        let position = calculateActualPosition(updatedText, offset, true);
        updatedText = deleteText(updatedText, position, op.delete);
      }
    });

    return updatedText;
  }

  function calculateActualPosition(text, intendedPosition, isDelete = false) {
    let currentPosition = 0;
    let actualPosition = 0;

    while (currentPosition < intendedPosition && actualPosition < text.length) {
      if (
        text[actualPosition] === "{" &&
        text.substring(actualPosition).startsWith('{"metadata":{')
      ) {
        // Find the end of the metadata block
        let endOfBlock = text.indexOf("}}", actualPosition) + 2; // +2 to include the closing "}}"
        if (endOfBlock !== -1) {
          // Ensure closing "}}" was found
          actualPosition = endOfBlock; // Skip past the metadata block
        } else {
          actualPosition++; // Malformed metadata, increment to avoid infinite loop
        }
      } else {
        // Check for consecutive newline characters
        if (
          text[actualPosition] === "\n" &&
          text[actualPosition + 1] === "\n"
        ) {
          if (currentPosition < intendedPosition - 1) {
            currentPosition++;
          }
          actualPosition += 2;
        } else {
          currentPosition++;
          actualPosition++;
        }
      }
    }

    if (text.substring(actualPosition).startsWith('{"metadata":{')) {
      // Adjust for deletions, if necessary
      //detect if last word of paragraph
      let endOfBlock = text.indexOf("}}", actualPosition) + 2;
      let metadataString = text.substring(actualPosition, endOfBlock);
      let metadataObject;
      try {
        metadataObject = JSON.parse(metadataString); // Attempt to parse the metadata string to an object
      } catch (error) {
        console.error("Error parsing metadata:", error);
      }

      // Assuming metadataObject is structured as expected
      if (metadataObject && metadataObject.metadata) {
        let { confidence, start, end } = metadataObject.metadata;
        // Check if confidence, start, and end are not empty
        let finalPos = endOfBlock;
        if (confidence !== "" && start !== "" && end !== "") {
          if (text[finalPos] === " " && text[finalPos + 1] != "\n")
            return finalPos;

          if (text[finalPos] === " " && text[finalPos + 1] === "\n") {
            return finalPos + 1;
          }
          if (text[finalPos] === undefined) {
            return finalPos + 1;
          }
          if (isDelete) return finalPos;
        }
      }
    }

    return actualPosition;
  }

  function insertText(originalText, textToInsert, position) {
    let beforeText = originalText.substring(0, position);
    let afterText = originalText.substring(position);
    let metadata = { metadata: { confidence: "", start: "", end: "" } };
    if (textToInsert === " " || textToInsert === "\n") {
      if (
        // This condition is to insert space between an existing word
        position > 0 &&
        position < originalText.length &&
        !/\s/.test(originalText[position - 1]) &&
        !/\s/.test(originalText[position])
      ) {
        if (afterText.startsWith("{")) {
          let closingBracketIndex = afterText.indexOf("}");
          if (closingBracketIndex !== -1) {
            // Insert a space right after the closing bracket
            afterText =
              afterText.substring(0, closingBracketIndex + 1) +
              " " +
              afterText.substring(closingBracketIndex + 1);
          }
          return beforeText + afterText;
        } else {
          afterText = afterText.replace(
            /^\{"metadata":\{.*?\}\}/,
            JSON.stringify(metadata)
          );
          beforeText += JSON.stringify(metadata) + " ";
          return beforeText + afterText;
        }
      } else {
        return beforeText + textToInsert + afterText;
      }
    } else {
      let isEndOfWord = /(?:\s|^)\{.*?\}\s/.test(afterText);

      let isStart =
        position === 0 ||
        beforeText[position - 1] === " " ||
        beforeText[position - 1] === "\n";

      if (isEndOfWord) {
        let endOfConfidenceScore = beforeText.lastIndexOf("}") + 1;
        let wordWithConfidenceScore = beforeText.substring(
          endOfConfidenceScore - 1
        );
        beforeText = beforeText.substring(0, endOfConfidenceScore - 1);
        afterText = afterText.replace(
          /^\{"metadata":\{.*?\}\}/,
          JSON.stringify(metadata)
        );
        return beforeText + wordWithConfidenceScore + textToInsert + afterText;
      } else if (isStart) {
        if (afterText.startsWith(" ") || afterText.startsWith("\n")) {
          return (
            beforeText +
            textToInsert +
            JSON.stringify(metadata) +
            (afterText.startsWith(" ") ? "" : " ") +
            afterText
          );
        } else {
          afterText = afterText.replace(
            /(\S?)\{"metadata":\{"confidence":"[0-9.]*","start":"[0-9.]*","end":"[0-9.]*"\}\}/,
            `$1${JSON.stringify(metadata)}`
          );
          return beforeText + textToInsert + afterText;
        }
      } else {
        let modifiedAfterText = afterText.replace(
          /(\S?)\{"metadata":\{"confidence":"[0-9.]*","start":"[0-9.]*","end":"[0-9.]*"\}\}/,
          `$1${JSON.stringify(metadata)}`
        );
        return beforeText + textToInsert + modifiedAfterText;
      }
    }
  }

  function deleteText(originalText, position, length) {
    let beforeText = originalText.substring(0, position);
    let afterText = originalText.substring(position + length);

    // Define the new metadata pattern to match the updated structure
    let metadataPattern =
      /\{"metadata":\{"confidence":"[0-9.]*","start":"[0-9.]*","end":"[0-9.]*"\}\}/;
    // Scenario: Deleting a space that might combine words
    if (length === 1 && originalText[position] === " ") {
      // Adjust the check to use the actual positions in the modified text
      let beforePattern = new RegExp(`(\\S+)${metadataPattern.source}\\s?$`);
      let afterPattern = new RegExp(`^\\s?(\\S+)${metadataPattern.source}`);

      if (
        beforePattern.test(beforeText) &&
        afterPattern.test(afterText) &&
        !originalText.substring(position - 1, position + 2).match(/\s\s/)
      ) {
        // Apply the logic for removing and emptying confidence scores
        let metadataReplacement =
          '{"metadata":{"confidence":"","start":"","end":""}}';
        beforeText = beforeText.replace(beforePattern, "$1");
        afterText = afterText.replace(afterPattern, `$1${metadataReplacement}`);
      }
    } else {
      let beforePattern = new RegExp(`(\\S+)${metadataPattern.source}\\s*$`);
      let afterPattern = new RegExp(`^${metadataPattern.source}`);

      // When an entire word with its confidence score is being deleted
      let beforeMatch = beforeText.match(beforePattern);
      if (beforeMatch && beforeText.endsWith(beforeMatch[0])) {
        afterText = afterText.replace(afterPattern, "");
      } else {
        // Adjust for other deletions
        let wordWithScoreAhead = afterText.match(
          new RegExp(`^\\S+${metadataPattern.source}`)
        );
        if (wordWithScoreAhead) {
          let metadataReplacement =
            '{"metadata":{"confidence":"","start":"","end":""}}';
          afterText = afterText.replace(
            new RegExp(`^\\S+${metadataPattern.source}`),
            wordWithScoreAhead[0].split("{")[0] + metadataReplacement
          );
        }
      }
    }

    return beforeText + afterText;
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        magicButtonRef.current &&
        !magicButtonRef.current.contains(event.target)
      ) {
        hideLogo();
      }
      if (
        customMenuRef.current &&
        !customMenuRef.current.contains(event.target)
      ) {
        console.log("coming herrrre");
        hideUserActions();
        setMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTextChange = async (delta, oldContents, source) => {
    if (source === "user") {
      setChangeDetected(true);
      const quill = props.quillRef.current.getEditor();
      const range = quill.getSelection();
      if (range) {
        const text = quill.getText();

        // Detecting space insertion by analyzing the delta.
        // A space insertion is characterized by an 'insert' operation with a value of ' '
        // and occurs at the range's index.
        const spaceInserted =
          delta.ops.length === 2 &&
          delta.ops[1].insert === " " &&
          delta.ops[1].attributes &&
          delta.ops[1].attributes.background === "#d8d2e0";

        if (spaceInserted) {
          // Adjust indices to cover the word before the inserted space
          let startIndexBefore = range.index - 1;
          while (
            startIndexBefore > 0 &&
            text[startIndexBefore - 1] !== " " &&
            text[startIndexBefore - 1] !== "\n"
          ) {
            startIndexBefore--;
          }

          // Adjust indices to cover the word after the inserted space
          let endIndexAfter = range.index;
          while (
            endIndexAfter < text.length &&
            text[endIndexAfter] !== " " &&
            text[endIndexAfter] !== "\n"
          ) {
            endIndexAfter++;
          }

          // Remove the background color for the word before the inserted space
          const wordFormatBefore = quill.getFormat(
            startIndexBefore,
            range.index - startIndexBefore
          );
          if (wordFormatBefore["background"] === "#d8d2e0") {
            quill.formatText(
              startIndexBefore,
              range.index - startIndexBefore,
              "background",
              false
            );
          }

          // Remove the background color for the word after the inserted space
          const wordFormatAfter = quill.getFormat(
            range.index,
            endIndexAfter - range.index
          );
          if (wordFormatAfter["background"] === "#d8d2e0") {
            quill.formatText(
              range.index,
              endIndexAfter - range.index,
              "background",
              false
            );
          }
        } else if (range.length === 0) {
          // Get the text of the entire editor for scanning.
          const text = quill.getText();

          // Find the start and end indices of the word being edited.
          let startIndex = range.index;
          let endIndex = range.index;

          // Move backward to the start of the word.
          while (
            startIndex > 0 &&
            text[startIndex - 1] !== " " &&
            text[startIndex - 1] !== "\n"
          ) {
            startIndex--;
          }
          // Move forward to the end of the word.
          while (
            endIndex < text.length &&
            text[endIndex] !== " " &&
            text[endIndex] !== "\n"
          ) {
            endIndex++;
          }

          // Now, check if the word had the specified background format.
          const wordFormat = quill.getFormat(
            startIndex,
            endIndex - startIndex - 1
          );
          if (wordFormat["background"] === "#d8d2e0") {
            // If so, remove the background format from the entire word.
            quill.formatText(
              startIndex,
              endIndex - startIndex,
              "background",
              false
            );
          }
        } else {
          // For selected ranges, the approach is straightforward.
          quill.formatText(range.index, range.length, "background", false);
        }
      }
      let html = quill.root.innerHTML;

      let updatedText = "";
      if (props.tabTag.includes("transcription")) {
        updatedText = await applyDeltaToOriginalText(
          props.originalContent,
          delta
        );
      }

      props.onContentChange(
        props.tabName,
        html,
        props.tabIndex,
        false,
        updatedText
      );

      hideLogo();
      hideUserActions();
    }
  };

  const calculatePosition = (quill, selection) => {
    const { index, length } = selection;
    const editorBounds = quill.container.getBoundingClientRect();

    if (length > 0) {
      const bounds = quill.getBounds({ index: index + length - 1, length: 0 });
      let position = {
        x: bounds.left + editorBounds.left - 4,
        y: editorBounds.top + bounds.top + 10,
      };
      return position;
    } else {
      const bounds = quill.getBounds(index);
      let position = {
        x: bounds.left + editorBounds.left + 10,
        y: editorBounds.top + bounds.top + 20,
      };
      if (editorBounds.right <= position.x + 220) {
        position.x = position.x - 220;
      }
      if (editorBounds.bottom <= position.y + 120) {
        position.y = position.y - 120;
      }
      setMenuPosition(position);
      setMenuOpen(true);
    }
  };

  const generateMenu = (quill) => {
    const selection = quill.getSelection();
    if (selection.length === 0) {
      calculatePosition(quill, selection);
    } else {
      const position = calculatePosition(quill, selection);
      showUserActions(position);
    }
  };

  function applyHighlight() {
    props.reRenderTranscription(
      props.tabName,
      props.tabIndex,
      props.originalContent,
      confidenceThreshold,
      thresholdStatus,
      true
    );
  }

  function removeHighlight() {
    props.reRenderTranscription(
      props.tabName,
      props.tabIndex,
      props.originalContent,
      confidenceThreshold,
      thresholdStatus
    );
  }

  useEffect(() => {
    let quill = props.quillRef.current.getEditor();
    if (props.factCheckMode) setFactCheckCSS("fact-check");

    const handleShortcutKey = (e) => {
      // if (e.key === "/") {
      //   const cursorPosition = quill.getSelection()?.index; // Get the current cursor position
      //   console.log(cursorPosition);
      //   if (cursorPosition > 0) {
      //     // Ensure there is a character before '/'
      //     const textBeforeCursor = quill.getText(cursorPosition - 1, 1);
      //     console.log(textBeforeCursor);

      //     // Check if the character before cursor is a whitespace character
      //     if (/\s/.test(textBeforeCursor)) {
      //       e.preventDefault();
      //       generateMenu(quill);
      //       return; // Stop further processing
      //     }
      //     // Else, it inserts '/' normally
      //   } else if (cursorPosition === 0) {
      //     e.preventDefault();
      //     generateMenu(quill);
      //     return;
      //   }
      // }
      // if ((e.ctrlKey || e.metaKey) && e.key === "/") {
      //   e.preventDefault();
      //   generateMenu(quill);
      // }
      if (props.tabTag.includes("transcription")) {
        if (e.key === "Alt") {
          // Prevent default to avoid any browser-specific Alt key behavior
          e.preventDefault();
          applyHighlight();
        }
      }
    };

    const handleClickEvent = (e) => {
      if (props.tabTag.includes("transcription") && e.altKey) {
        let position = calculateActualPosition(
          props.originalContent,
          quill.getSelection().index
        );
        let afterText = props.originalContent.substring(position);
        const metadataRegex = /"start":"(\d*(\.\d+)?)"/;
        const match = metadataRegex.exec(afterText);

        if (match) {
          const startValue = match[1];
          if (startValue === "") {
            return null;
          }

          setAudioStartTime(startValue);
          return startValue;
        }
      }
    };

    const handleKeyUp = (e) => {
      if (props.tabTag.includes("transcription")) {
        if (e.key === "Alt") {
          // Prevent default to avoid any browser-specific Alt key behavior
          e.preventDefault();
          removeHighlight();
          removeTooltip();
        }
      }
    };

    function showTooltip(event) {
      const tooltip = document.createElement("div");
      const target = event.target.closest(".audioWord");
      const startValue = target.getAttribute("data-start-value");

      tooltip.innerHTML = `
<div style="display: flex; align-items: center; gap: 8px; font-size: 0.75rem"> 
  <svg height="18" width="18" xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 24 24" class="icon-play-circle">
    <circle cx="12" cy="12" r="8" stroke="white" stroke-width="2" fill="none" />
    <polygon points="10,8 16,12 10,16" fill="white"/>
  </svg>
  <span>${startValue} seconds</span>
</div>
`;

      tooltip.style.position = "absolute";
      tooltip.style.left = `${event.pageX}px`;
      tooltip.style.top = `${event.pageY + 20}px `;
      tooltip.style.backgroundColor = "#7c7a7a";
      tooltip.style.color = "white";
      tooltip.style.padding = "3px 5px";
      tooltip.style.fontSize = "0.875rem";
      tooltip.style.borderRadius = "var(--border-radius)";
      tooltip.style.zIndex = "1000";
      tooltip.setAttribute("id", "audioWordTooltip");

      document.body.appendChild(tooltip);
    }

    // Function to remove the tooltip
    function removeTooltip() {
      const tooltip = document.getElementById("audioWordTooltip");
      if (tooltip) {
        document.body.removeChild(tooltip);
      }
    }

    // Add event listeners to all .audioWord elements
    document.querySelectorAll(".audioWord").forEach((element) => {
      element.addEventListener("mouseenter", showTooltip);
      element.addEventListener("mouseleave", removeTooltip);
    });

    const handleTextSelection = (range, oldRange, source) => {
      if (source === "user") {
        const editor_selection = quill.getSelection();
        if (editor_selection) {
          const selLength = editor_selection.length;
          setMenuOpen(false);
          hideUserActions();
          if (selLength > 0) {
            const startIndex = editor_selection.index;
            const endIndex = startIndex + selLength;
            const textBeforeSelection = quill.getText(0, startIndex, "SILENT");
            const startWordIndex = textBeforeSelection.lastIndexOf(" ");
            let adjustedStartIndex = startIndex;
            if (
              startWordIndex !== startIndex - 1 &&
              textBeforeSelection.charAt(startIndex - 1) !== "\n"
            ) {
              adjustedStartIndex =
                textBeforeSelection.lastIndexOf(" ", startIndex - 2) + 1;
            }

            const charAtEndIndex = quill.getText(endIndex, 1, "SILENT");
            const isEndIndexAtWordBoundary =
              charAtEndIndex === " " || charAtEndIndex === "";
            let adjustedEndIndex = isEndIndexAtWordBoundary
              ? endIndex
              : endIndex + 1;
            if (!isEndIndexAtWordBoundary) {
              while (
                adjustedEndIndex < quill.getLength("SILENT") &&
                !/\s/.test(quill.getText(adjustedEndIndex, 1, "SILENT"))
              )
                adjustedEndIndex++;
            }

            const adjustedSelLength = adjustedEndIndex - adjustedStartIndex;
            const selectedText = quill.getText(
              adjustedStartIndex,
              adjustedSelLength,
              "SILENT"
            );

            const contextText = quill.getText(0, adjustedStartIndex);
            const words = contextText.trim().split(/\s+/);
            const contextStartIndex = Math.max(0, words.length - 250);
            const context = words.slice(contextStartIndex).join(" ");

            wordCount = selectedText.split(/\s+/).length;
            const selectedTextInfo = JSON.stringify({
              startIndex: adjustedStartIndex,
              endIndex: adjustedEndIndex,
              length: adjustedSelLength,
              selectedText: selectedText,
              contentLength: quill.getLength(),
              context,
            });

            props.setSelectedText(selectedTextInfo);

            selectedTextRef.current = selectedTextInfo;

            renderLogo(quill, editor_selection);
          }
          // else {
          //   console.log("Simply clicked hahhaha");
          //   const index = editor_selection.index;
          //   let position = calculateActualPosition(
          //     props.originalContent,
          //     index
          //   );
          //   let afterText = props.originalContent.substring(position);

          //   setAudioStartTime("");

          //   //Here get the currect word
          //   //if it has start value, move the audio player to that time
          // }
        }
      }
    };

    quill.on("text-change", handleTextChange);
    quill.on("selection-change", handleTextSelection);
    document.addEventListener("keydown", handleShortcutKey);
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("click", handleClickEvent);

    return () => {
      quill.off("text-change", handleTextChange);
      quill.off("selection-change", handleTextSelection);
      document.removeEventListener("keydown", handleShortcutKey);
      document.removeEventListener("click", handleClickEvent);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [
    isGenModalOpen,
    isOutlineLoading,
    isArticleLoading,
    isSummaryLoading,
    isError,
    missedKeywords,
    props.value,
    props.originalContent,
    // mode,
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (changeDetected) {
        saveContent(false);
        setChangeDetected(false);
      }
    }, 30000); // Auto-save every 30 seconds

    return () => clearInterval(intervalId);
  }, [changeDetected]);

  function stripExtension(filename) {
    return filename.substr(0, filename.lastIndexOf(".")) || filename;
  }

  const callSummarizeApi = async function (summaryLength) {
    setMenuOpen(false);
    const quill = props.quillRef.current.getEditor();
    const editorContent = quill.getText();
    // need to change tagging mechanism
    const fileName = props.generateFileName(
      `${stripExtension(props.tabName)}_summary.docx`
    );
    quill.focus();
    props.onContentChange(fileName, "", props.tabIndex, false);
    props.onTabLoading(true, props.tabIndex);
    setSummaryLoading(true);
    try {
      const response = await api.post(
        "ai/summarize",
        {
          fileName: props.tabName,
          projectId: props.projectId,
          text: editorContent,
          type: summaryLength,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSummaryLoading(false);
      if (response.status != 200) {
        throw new Error("Error  calling summarize api");
      }

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      const summary = response.data.summary;
      await simulateHTMLStream(summary, fileName);
      quill.setSelection(quill.getLength(), quill.getLength(), "silent");
      props.saveFile(
        summary,
        fileName,
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "summary"
      );
      props.onTabLoading(false, props.tabIndex);
      setSummarizeOptions(false);
    } catch (error) {
      setSummaryLoading(false);
      console.error("Error:", error);
    }
  };

  const callBlogGenAPI = async function (resource) {
    setArticleLoading(true);
    const fileName = props.generateFileName("draft.txt");
    console.log(fileName);
    setMenuOpen(false);
    setError(false);
    const quill = props.quillRef.current.getEditor();
    quill.focus();
    props.onContentChange(fileName, "", props.tabIndex, false);
    props.onTabLoading(true, props.tabIndex);

    try {
      const requestData = {
        resource: resource,
      };
      const response = await api_external.post(
        env.salesPubAPI + "/text-gen-from-podcast",
        requestData
      );

      if (!response.status === 200) {
        const errorMessage = response.data.error;
        throw new Error(errorMessage);
      }
      const article = response.data.generatedContent[0].content;
      // log the usage
      api.post("/user/logAsyncServiceCall", {
        projectId: props.projectId,
        service_tag: "generateArticleWithStyle",
        service_parent_id: null,
        service_filename: null,
        options: {
          service_progress_status: false,
          service_payload: {
            input: {
              resource: props.tabName,
            },
            output: response.data,
          },
        },
        logs: response.data.usage,
      });
      const regex = /\*\*(.*?)\*\*/g;
      let formatted_article = article.replace(
        regex,
        (match, p1) => `<strong>${p1}</strong>`
      );
      formatted_article = formatted_article.replace(
        /<strong>(.*?)<\/strong>/,
        "<h2><strong>$1</strong></h2>"
      );
      await simulateHTMLStream(formatted_article, fileName);
      props.saveFile(quill.root.innerHTML, fileName, "text/plain", "article");
      quill.setSelection(quill.getLength(), quill.getLength(), "silent");
      props.onContentChange(
        fileName,
        quill.root.innerHTML,
        props.tabIndex,
        false,
        "",
        ["article"]
      );
    } catch (error) {
      console.error("API error:", error);
      setError(true);
      setErrorMsg(error.message);
      setMenuOpen(true);
    } finally {
      setArticleLoading(false);
      props.onTabLoading(false, props.tabIndex);
    }
  };

  const callOutlineApi = async function (objective, fileIdsForGen) {
    setOutlineLoading(true);
    setMenuOpen(false);
    setError(false);
    const quill = props.quillRef.current.getEditor();
    quill.focus();
    const fileName = props.generateFileName("outline.txt");
    props.onContentChange(fileName, "", props.tabIndex, false);
    props.onTabLoading(true, props.tabIndex);
    props.quillRef.current.scrollTop = 0;

    const containerHeight = props.quillRef.current.clientHeight;
    const scrollOffset = containerHeight * 0.5;

    props.quillRef.current.scrollTop -= scrollOffset;
    const userToken = localStorage.getItem("token");

    try {
      const response = await api.post(
        "ai/outline",
        {
          objective: objective,
          fileName: fileName,
          projectId: props.projectId,
          token: userToken,
          fileList: fileIdsForGen,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const outline = response.data.outline;
      await simulateHTMLStream(outline, fileName);
      setOutline(outline);
    } catch (error) {
      console.error("API error:", error);
      setError(true);
      setErrorMsg(error.message);
      setMenuOpen(true);
    } finally {
      setOutlineLoading(false);
      props.onTabLoading(false, props.tabIndex);
      setEnableArticleGen(true);
      generateMenu(quill);
    }
  };

  const simulateHTMLStream = async function (
    text,
    fileName,
    replaceNewline = true
  ) {
    const delay = 1; // Adjust delay as needed for optimal performance
    const words = text.split(" ");
    let index = 0;

    const processNextWord = () => {
      if (index < words.length) {
        let word = words[index];
        if (!word.endsWith("\n")) {
          word += " ";
        }
        let formattedWord = replaceNewline ? word.replace(/\n/g, "<br>") : word;
        props.onContentChange(fileName, formattedWord, props.tabIndex, true);
        index++;
      } else {
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(processNextWord, delay);
  };

  function identifyAndFormatTitle(text, quill) {
    const lines = text.split("\n");
    const firstLine = lines[0];
    const words = firstLine.split(" ");

    let capitalizedWords = 0;
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (word.length > 0 && word[0] === word[0].toUpperCase()) {
        capitalizedWords++;
      }
    }

    const capitalizationPercentage = capitalizedWords / words.length;
    const threshold = 0.5; // Adjust this threshold as needed

    if (capitalizationPercentage >= threshold) {
      // Format the first line as a header
      quill.formatText(0, firstLine.length, "bold", true, "silent");
      quill.formatLine(0, 1, "header", 1, "silent");
    }
  }

  const callTextGenAPI = async function (
    objective,
    mainSourceID,
    supportedSourceList,
    style_profile
  ) {
    setArticleLoading(true);
    const fileName = props.generateFileName("draft.txt");
    setMenuOpen(false);
    setError(false);
    const quill = props.quillRef.current.getEditor();
    quill.focus();
    props.onContentChange(fileName, "", props.tabIndex, false);
    props.onTabLoading(true, props.tabIndex);

    // if we are coming from the create project page, the article will be generated for the first time
    if (objective.topic === undefined || objective.topic === "") {
      return;
    }

    try {
      const response = await api.post(
        "ai/textGenWithStyle",
        {
          objective: objective,
          projectId: props.projectId,
          mainResourceFileId: mainSourceID,
          supportingResourcesFileList: supportedSourceList,
          style_profile: style_profile,
          fileName: fileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.status === 200) {
        const errorMessage = response.data.error;
        throw new Error(errorMessage);
      }
      const article = response.data.article;
      await simulateHTMLStream(article, fileName);
      props.saveFile(quill.root.innerHTML, fileName, "text/plain", "article");
      if (objective.structure !== "true crime podcast script")
        // quill.formatLine(0, 1, "header", true, "silent");
        identifyAndFormatTitle(article, quill);
      quill.setSelection(quill.getLength(), quill.getLength(), "silent");
      props.onContentChange(
        fileName,
        quill.root.innerHTML,
        props.tabIndex,
        false
      );
    } catch (error) {
      console.error("API error:", error);
      setError(true);
      setErrorMsg(error.message);
      setMenuOpen(true);
    } finally {
      setArticleLoading(false);
      props.onTabLoading(false, props.tabIndex);
    }
  };

  const fetchFiles = async () => {
    try {
      const fileList = await api.get("file/getFiles", {
        params: { projectId: props.projectId, isGUI: true },
        headers: { "Cache-Control": "no-cache" },
      });
      return fileList.data;
    } catch (err) {
      throw err;
    }
  };

  const formatFileRefToFilename = async (article) => {
    // Retrieve the array of files from the API
    const files = await fetchFiles();

    // Regex pattern to match file references within <a> tags
    const fileCitationRegex =
      /<a class=xpub_ref file_ref href='([^']+)' rel='noopener noreferrer' target='_blank'><sup>\[(\d+)\]<\/sup><\/a>/g;

    // Function to replace each <a> tag with a standalone <sup> showing the filename
    const replaceCallback = (match, fileId, fileIndex) => {
      // Find the corresponding file name for the given fileId
      const file = files.find((f) => f.id === fileId);
      const filename = file ? file.name : "unknown file";

      // Return the <sup> tag with a title attribute to show the filename as a tooltip
      return `<sup data-file-name="${filename}">[${fileIndex}]</sup>`;
    };

    // Replace all file references, stripping the <a> tags and adding tooltips to <sup>
    article = article.replace(fileCitationRegex, (match, fileId, fileIndex) =>
      replaceCallback(match, fileId, fileIndex)
    );

    return article;
  };

  const callIdeaGenAPI = async function (
    objective,
    resources,
    seoKeywords,
    styleFiles
  ) {
    setArticleLoading(true);
    const fileName = props.generateFileName("draft.txt");
    setMenuOpen(false);
    setError(false);
    const quill = props.quillRef.current.getEditor();
    quill.focus();
    props.onContentChange(fileName, "", props.tabIndex, false);
    props.onTabLoading(true, props.tabIndex);

    // if we are coming from the create project page, the article will be generated for the first time
    if (objective.idea === undefined || objective.idea === "") {
      return;
    }

    try {
      //Check if user has free credits
      const res = await api.get("user/checkCredit");
      if (res.data.creditsAvailable) {
        const requestData = {
          resources: resources,
          style_examples: styleFiles,
          idea: objective.idea.trim(),
          seo_keywords: seoKeywords,
        };
        const response = await api_external.post(
          env.salesPubAPI + "/text-gen-from-idea",
          requestData
        );
      }
      if (!response.status === 200) {
        const errorMessage = response.data.error;
        throw new Error(errorMessage);
      }
      const article = response.data.generatedContent[0].content;
      const missedKeywords = response.data.missedKeywords;
      // log the usage
      api.post("/user/logAsyncServiceCall", {
        projectId: props.projectId,
        service_tag: "generateArticleWithStyle",
        service_parent_id: null,
        service_filename: null,
        options: {
          service_progress_status: false,
          service_payload: {
            input: {
              resources: resources,
              objective: objective,
              style_files: styleFiles,
            },
            output: {
              content: response.data.generatedContent[0].content,
              sources: response.data.sources,
              missing: response.data.missedKeywords,
              usage: response.data.usage,
            },
          },
        },
        logs: response.data.usage,
      });
      let formatted_article = article;

      await simulateHTMLStream(formatted_article, fileName, false);
      props.saveFile(quill.root.innerHTML, fileName, "text/plain", "article");

      quill.setSelection(quill.getLength(), quill.getLength(), "silent");
      props.onContentChange(
        fileName,
        quill.root.innerHTML,
        props.tabIndex,
        false
      );
      setMissedKeywords(missedKeywords);
    } catch (error) {
      console.error("API error:", error);
      setError(true);
      setErrorMsg(error.message);
      setMenuOpen(true);
    } finally {
      setArticleLoading(false);
      props.onTabLoading(false, props.tabIndex);
    }
  };

  const callArticleApi = async function () {
    setArticleLoading(true);
    const fileName = props.generateFileName("draft.txt");
    setMenuOpen(false);
    setError(false);
    const quill = props.quillRef.current.getEditor();
    quill.focus();
    props.onContentChange(fileName, "", props.tabIndex, false);
    props.onTabLoading(true, props.tabIndex);

    // if we are coming from the create project page, the article will be generated for the first time
    if (objective.topic === undefined || objective.topic === "") {
      return;
    }

    const userToken = localStorage.getItem("token");

    try {
      const response = await api.post(
        "ai/article-from-oultine",
        {
          objective: objective,
          projectId: props.projectId,
          outline: props.value !== "" ? props.value : outline,
          fileName: fileName,
          token: userToken,
          fileList: fileIdsForGen,
          style_prompt: props.stylePrompt,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.status === 200) {
        const errorMessage = response.data.error;
        throw new Error(errorMessage);
      }
      const article = response.data.article;
      await simulateHTMLStream(article, fileName);
      props.saveFile(quill.root.innerHTML, fileName, "text/plain", "article");
      // quill.formatLine(0, 1, "header", true, "silent");
      identifyAndFormatTitle(article, quill);
      quill.setSelection(quill.getLength(), quill.getLength(), "silent");
      props.onContentChange(
        fileName,
        quill.root.innerHTML,
        props.tabIndex,
        false
      );
    } catch (error) {
      console.error("API error:", error);
      setError(true);
      setErrorMsg(error.message);
      setMenuOpen(true);
    } finally {
      setArticleLoading(false);
      props.onTabLoading(false, props.tabIndex);
    }
  };

  const closeOverflowMenu = () => {
    setMenuOpen(false);
  };

  const summarizeItems = [
    {
      functionCall: () => callSummarizeApi("short"),
      menuText: "- short",
      subMenuItem: true,
    },
    {
      functionCall: () => callSummarizeApi("medium"),
      menuText: "- medium",
      subMenuItem: true,
    },
    {
      functionCall: () => callSummarizeApi("long"),
      menuText: "- long",
      subMenuItem: true,
    },
  ];

  const textToSpeech = async function () {
    setMenuOpen(false);
    const quill = props.quillRef.current.getEditor();
    props.onTabLoading(true, props.tabIndex);
    setGenPodcast(true);
    try {
      let response = await api.post(
        "ai/createSpeech",
        {
          fileName: props.tabName,
          projectId: props.projectId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status != 200) {
        throw new Error("Error  calling text to speech api");
      }

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      const fileId = response.data.fileId;
      //get signed url
      const signedResponse = await api.get(`file/getSignedURL/${fileId}`);
      const url = signedResponse.data.url;
      // download file to local computer
      const fileName = props.generateFileName(
        `${stripExtension(props.tabName)}_podcast.mp3`
      );
      response = await fetch(url);
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(blobUrl);
      props.onTabLoading(false, props.tabIndex);
      setGenPodcast(false);
      props.refreshFiles();
    } catch (error) {
      setGenPodcast(false);
      console.error("Error:", error);
    }
  };

  const outlineItems = [
    // props.user.organizationId != "661d76f93415c33072369bf3" &&
    //   props.user.organizationId != "661d697a4871d02c6dc0d438" && {
    //     functionCall: () => {
    //       setGenModalOpen(true);
    //       setIsDraft(true);
    //       setRefreshGenModal(true);
    //     },
    //     menuText:
    //       props.user.email && props.user.email.includes("veem")
    //         ? "Generate podcast script"
    //         : "Generate draft",
    //   },

    props.user.organizationId != "661d697a4871d02c6dc0d438" && {
      // temporary for adding option
      functionCall: () => {
        setRefreshIdeaGenModal(true);
        setIdeaGenModalOpen(true);
        setIsDraft(true);
      },
      menuText: "Generate SEO content",
    },

    props.user.organizationId === "661d697a4871d02c6dc0d438" && {
      functionCall: () => {
        setBlogGenModalOpen(true);
        setRefreshBlogGenModal(true);
        setIsDraft(true);
      },
      menuText: "Generate draft from podcast",
    },
    {
      functionCall: () => textToSpeech(),
      menuText:
        props.user.email && props.user.email.includes("veem")
          ? "Generate podcast"
          : "Generate audio from text",
    },
    process.env.REACT_APP_ENV === "staging" && {
      functionCall: () => {
        setGenModalOpen(true);
        setIsDraft(false);
        setRefreshGenModal(true);
      },
      menuText: "Generate outline",
    },
    {
      functionCall: () => setSummarizeOptions(!summarizeOptions),
      menuText: "Summarize",
    },
    ...(summarizeOptions ? summarizeItems : []),
    {
      functionCall: () => {
        setTranslationModalOpen(true);
        setTranslateFullText(true);
      },
      menuText: "Translate document",
    },

    process.env.REACT_APP_ENV === "staging" && {
      functionCall: () => {
        props.toggleFeedbackMode(true);
      },
      menuText: "Critical thinking",
    },
  ];

  const articleItems = [
    {
      functionCall: () => setGenModalOpen(true),
      menuText: "Regenerate outline",
    },
    {
      functionCall: () => callArticleApi(),
      menuText: "Generate article",
    },
    ...(summarizeOptions ? summarizeItems : []),
    process.env.REACT_APP_ENV === "staging" && {
      functionCall: () => {
        props.toggleFeedbackMode(true);
      },
      menuText: "Critical thinking",
    },
    // Add more menu items as needed
  ];

  const getTextMenu = () => {
    return [
      {
        functionCall: () => {
          props.toggleFactCheckMode(true);
          props.setFactCheck(true);
        },
        menuText: "Fact-check",
        disableOption: wordCount > MAX_FACTCHECK_SIZE ? true : false,
        disabledMsg: {
          text: `max. ${MAX_FACTCHECK_SIZE} words`,
        },
      },
      {
        functionCall: () => {
          setTranslationModalOpen(true);
          setTranslateFullText(false);
        },
        menuText: "Translate selection",
      },
    ];
  };

  const setGenModalState = (val) => {
    setGenModalOpen(val);
  };

  const setFileIdsForGeneration = (fileIds) => {
    setFileIdsForGen(fileIds);
  };

  const setObjectiveForGeneration = (obj) => {
    setObjective(obj);
  };

  const setHeight = () => {
    if (props.factCheckMode || props.feedbackMode)
      return "calc(100vh - 4.2rem)";
    else if (props.tabTag.includes("transcription")) {
      if (props.isSaving) return "calc(100vh - 3rem - 7rem - 11rem)";
      else return "calc(100vh - 7rem - 11rem)";
    } else if (
      isError ||
      props.tabFileType === FileExtensionMimeTypes[".pdf"] ||
      props.isSaving ||
      (props.missedKeywords && props.missedKeywords.length > 0) ||
      props.showErrorMsg ||
      props.showTranscriptionMsg ||
      props.transcriptionError
    )
      return "calc(100vh - 3rem - 8rem)";
    else return "calc(100vh - 8rem)";
  };

  const renderLogo = (quill, editor_selection) => {
    const position = calculatePosition(quill, editor_selection);
    const container = document.createElement("div");
    container.id = "newspubLogo";
    container.style.position = "absolute";
    container.style.top = `${position.y + 10}px`;
    container.style.left = `${position.x - 25}px`;
    container.style.zIndex = "999";

    document.body.appendChild(container);

    const IconComponent = () => (
      <IconButton
        ref={magicButtonRef}
        label="Magic actions"
        kind="secondary"
        align="bottom"
        className="icon_button"
        size="sm"
        onClick={() => showUserActions(position)}
        style={{ position: "relative" }} // Add relative positioning
      >
        <img
          src={logo_slash}
          alt="Logo"
          style={{ width: "19px", height: "19px", zIndex: 1 }}
        />
        {/* <div alt='logo' style={{alignItems: "center", width: "100%", height: "100%", zIndex: 1, color: "var(--element-hover-color)" } }>
          <MagicWandFilled/>
        </div> */}

        <div
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "25px",
            height: "25px",
            zIndex: "0",
          }}
        ></div>
      </IconButton>
    );

    ReactDOM.render(<IconComponent />, container);
  };

  const hideLogo = () => {
    const elementToRemove = document.getElementById("newspubLogo");
    if (elementToRemove) {
      ReactDOM.unmountComponentAtNode(elementToRemove);
      elementToRemove.parentNode.removeChild(elementToRemove);
    }
  };

  const showUserActions = (position) => {
    hideLogo();
    const customMenuContainer = document.createElement("div");
    customMenuContainer.id = "userActions";
    customMenuContainer.classList.add("custom-menu-container");
    customMenuContainer.style.position = "absolute";
    customMenuContainer.style.top = `${position.y + 10}px`;
    customMenuContainer.style.left = `${position.x - 22}px`;
    customMenuContainer.style.zIndex = "999";
    customMenuContainer.style.visibility = "visible";
    customMenuContainer.ref = customMenuRef;

    // document.addEventListener('click', onDocumentClick);

    customMenuContainer.addEventListener("click", (e) => e.stopPropagation());

    // Create the menu header
    const menuHeader = document.createElement("div");
    menuHeader.classList.add("menu-header");

    // Create the menu title span
    const menuTitle = document.createElement("span");
    menuTitle.classList.add("menu-title");

    const mainText = document.createTextNode("Magic actions  ");
    menuTitle.appendChild(mainText);

    // Create the close button span
    const closeButton = document.createElement("span");
    closeButton.classList.add("close-button");
    closeButton.textContent = "x";
    closeButton.addEventListener("click", () => hideUserActions());

    // Append menuTitle and closeButton to menuHeader
    menuHeader.appendChild(menuTitle);
    menuHeader.appendChild(closeButton);

    // Create the menu items div
    const menuItemsDiv = document.createElement("div");
    menuItemsDiv.classList.add("menu-items");

    // Create loading element
    const loadingDiv = document.createElement("div");
    loadingDiv.style.marginLeft = "10px";

    // Append loadingDiv to menuItemsDiv
    menuItemsDiv.appendChild(loadingDiv);

    const menuItems = getTextMenu();

    // Iterate over menu items and create action items
    menuItems.forEach((item, index) => {
      const actionItem = document.createElement("div");
      actionItem.classList.add("action-item");
      if (item.subMenuItem) {
        actionItem.classList.add("submenu-item");
      }
      if (!props.enableGen) {
        actionItem.classList.add("disabled");
      }
      if (item.disableOption) {
        actionItem.classList.add("disabled");
      }
      actionItem.textContent = item.menuText;

      // Add a click event listener to call the function when clicked
      actionItem.addEventListener("click", () => {
        item.functionCall();
        hideUserActions();
        setMenuOpen(false);
      });

      // If disableOption is true, add a span for the disabled message
      if (item.disableOption) {
        const disabledMsgSpan = document.createElement("span");
        disabledMsgSpan.style.color = "#ff8080";
        disabledMsgSpan.style.fontSize = "0.875em";
        disabledMsgSpan.style.textAlign = "right";
        disabledMsgSpan.textContent = ` ${item.disabledMsg.text}`;
        disabledMsgSpan.style.paddingLeft = "50px";
        actionItem.appendChild(disabledMsgSpan);
      }

      // Append the action item to menuItemsDiv
      menuItemsDiv.appendChild(actionItem);
    });

    // Create the "Change Tone" disabled action item
    const changeToneItem = document.createElement("div");
    changeToneItem.classList.add("action-item", "disabled");
    changeToneItem.textContent = "Change Tone";

    // Append menuHeader and menuItemsDiv to customMenuContainer
    customMenuContainer.appendChild(menuHeader);
    customMenuContainer.appendChild(menuItemsDiv);

    document.body.appendChild(customMenuContainer);
  };

  const hideUserActions = () => {
    const elementToRemove = document.getElementById("userActions");
    if (elementToRemove) {
      elementToRemove.parentNode.removeChild(elementToRemove);
    }
    hideLogo();
  };

  const saveContent = (displaySaveInHeader = true) => {
    const quill = props.quillRef.current.getEditor();
    props.saveFile(
      quill.root.innerHTML,
      props.tabName,
      props.tabFileType,
      props.tabTag,
      props.originalContent,
      displaySaveInHeader
    );
  };

  return (
    <div>
      <TranslationModal
        isOpen={isTranslationModalOpen}
        onClose={() => {
          setTranslationModalOpen(false);
          setMenuOpen(false);
          setTranslateFullText(false);
        }}
        projectId={props.projectId}
        sourceText={
          translateFullText
            ? null
            : JSON.parse(selectedTextRef.current).selectedText
        }
        modalHeaderName={
          translateFullText ? "Translate document" : "Translate selection"
        }
        addTab={props.addTab}
        tabName={props.tabName}
        saveFile={props.saveFile}
        listItems={props.listItems}
        generateFileName={props.generateFileName}
      />
      <GenerationModal
        isOpen={isGenModalOpen}
        onClose={() => {
          setGenModalState(false);
          setMenuOpen(false);
        }}
        refreshFiles={props.refreshFiles}
        projectId={props.projectId}
        setFileIdsForGeneration={setFileIdsForGeneration}
        setObjectiveForGeneration={setObjectiveForGeneration}
        outlineAPICall={callOutlineApi}
        isDraft={isDraft}
        textGenAPICall={callTextGenAPI}
        refreshComponent={refreshGenModal}
      />
      {/* <IdeaGenerationModal
        isOpen={isIdeaGenModalOpen}
        onClose={() => {
          setIdeaGenModalOpen(false);
          setMenuOpen(false);
        }}
        refreshFiles={props.refreshFiles}
        projectId={props.projectId}
        setObjectiveForGeneration={setObjectiveForGeneration}
        isDraft={isDraft}
        ideaGenAPICall={callIdeaGenAPI}
        refreshComponent={refreshIdeaGenModal}
      /> */}
      <BlogGenerationModal
        isOpen={isBlogGenModalOpen}
        onClose={() => {
          setBlogGenModalOpen(false);
          setMenuOpen(false);
        }}
        refreshFiles={props.refreshFiles}
        projectId={props.projectId}
        isDraft={isDraft}
        blogGenAPICall={callBlogGenAPI}
        refreshComponent={refreshIdeaGenModal}
      />
      <div>
        {isOutlineLoading ? (
          <InlineLoading
            description="Hang tight! We are extracting relevant information from your research material."
            status="active"
            className="inline-loader-editor"
          />
        ) : null}
      </div>
      <div>
        {isArticleLoading ? (
          <InlineLoading
            description="Hang tight! We are crafting content according to your objective, input sources, and style. It should no longer than 2-3 minutes."
            status="active"
            className="inline-loader-editor"
          />
        ) : null}
      </div>
      <div>
        {isSummaryLoading ? (
          <InlineLoading
            description="Hang tight! We're crafting a summary of your text based on the length you indicated."
            status="active"
            className="inline-loader-editor"
          />
        ) : null}
      </div>
      <div>
        {isGeneratingPodcast ? (
          <InlineLoading
            description="Hang tight! We're creating an audio from the script."
            status="active"
            className="inline-loader-editor"
          />
        ) : null}
      </div>
      <div>
        {props.showLoadingMsg ? (
          <InlineLoading
            description={props.loadingMsg}
            status="active"
            className="inline-loader-editor"
          />
        ) : null}
      </div>
      <div style={{ width: "100%" }}>
        {props.showErrorMsg && (
          <InlineNotification
            lowContrast
            kind="error"
            subtitle={props.errorMsg}
            style={{ maxInlineSize: "70rem" }}
          />
        )}
      </div>
      <div style={{ width: "100%" }}>
        {isError ? (
          <InlineNotification
            lowContrast
            kind="error"
            subtitle={"Oops, something went wrong, please try again."} //errorMsg
            title="Error in Generation"
            hideCloseButton={false}
          />
        ) : null}
        {props.missedKeywords && props.missedKeywords.length > 0 ? (
          <InlineNotification
            lowContrast
            kind="warning"
            subtitle={`The following keywords could not be incorporated naturally into the text: ${props.missedKeywords.join(
              ", "
            )}`} //errorMsg
            title="Missing Keywords "
            hideCloseButton={false}
          />
        ) : null}
      </div>
      <div style={{ width: "100%" }}>
        {props.tabFileType === FileExtensionMimeTypes[".pdf"] ? (
          <InlineNotification
            lowContrast
            kind="info"
            subtitle={"PDF files are not editable. "} //errorMsg
            title="Read only mode: "
            hideCloseButton={true}
          />
        ) : null}
      </div>
      <div style={{ width: "100%" }}>
        {props.showTranscriptionMsg && (
          <InlineNotification
            lowContrast
            kind="success"
            subtitle={`Transcription file ready - ${props.showTranscriptionMsg}`}
          />
        )}
      </div>

      <div>
        {props.isSaving ? (
          <InlineNotification
            lowContrast
            kind="success"
            subtitle={`File ${props.tabName} saved successfully.`}
            hideCloseButton={true}
            timeout={5000}
          />
        ) : null}
      </div>
      <div>
        {props.transcriptionError && (
          <InlineNotification
            lowContrast
            kind="error"
            subtitle={props.transcriptionError}
          />
        )}
      </div>
      <div>
        <div className="editor-toolbar">
          <div className="toolbar-container">
            <EditorToolbar />
          </div>

          <div className="export-tab-container">
            <button
              className="export-tab-button"
              onClick={saveContent}
              disabled={props.tabLoading}
              title="Save file"
            >
              <p
                style={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                  fontSize: "var(--text-small)",
                  fontWeight: "500",
                }}
              >
                <Save size="16" /> Save
              </p>
            </button>
            <button
              className="export-tab-button"
              onClick={() => props.onExportDocument()}
              disabled={props.tabLoading}
              title="Export file"
            >
              <p
                style={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                  fontSize: "var(--text-small)",
                  fontWeight: "500",
                }}
              >
                <Download size="16" /> Export
              </p>
            </button>
          </div>
        </div>

        <ReactQuill
          ref={props.quillRef}
          value={props.value || ""}
          modules={modules}
          formats={formats}
          theme="snow"
          readOnly={
            props.tabFileType === FileExtensionMimeTypes[".pdf"] ? true : false
          }
          style={{
            height: setHeight(),
            border: "none",
          }}
        />
        {props.tabTag.includes("transcription") && (
          <AudioPlayer
            fileName={props.tabName}
            audioStartTime={audioStartTime}
            projectId={props.projectId}
            setThreshold={(val) => {
              setConfidenceThreshold(val);
              props.updateThreshold(val);
            }}
            setThresholdStatus={(val) => {
              setThresholdStatus(val);
              props.updateThresholdStatus(val);
            }}
            onRerender={(threshold, status) => {
              props.reRenderTranscription(
                props.tabName,
                props.tabIndex,
                props.originalContent,
                threshold,
                status
              );
            }}
          />
        )}
      </div>
      <div
        className="custom-overflow-menu"
        // id='userActions'
        style={{
          position: "absolute",
          top: menuPosition.y,
          left: menuPosition.x,
          zIndex: "999",
          visibility: menuOpen ? "visible" : "hidden",
        }}
      >
        <CustomMenu
          menuClose={closeOverflowMenu}
          menuItems={enableArticleGeneration ? articleItems : outlineItems}
          enableGen={props.enableGen}
        />
      </div>
    </div>
  );
};

export default QuillEditor;

// const callArticleApi = async function () {
//   const fileName = props.generateFileName("draft.txt");
//   setMenuOpen(false);
//   setError(false);
//   const quill = props.quillRef.current.getEditor();
//   quill.focus();
//   props.onContentChange(fileName, "", props.tabIndex, false);
//   props.onTabLoading(true, props.tabIndex);
//   // if we are coming from create project page, the article will be generated for the first time
//   if (props.objective.topic === undefined || props.objective.topic === "") {
//     return;
//   }
//   const userToken = localStorage.getItem("token");
//   const socket = new WebSocket(streamURL + "/ai/article-from-oultine");

//   socket.onopen = () => {
//     console.log("WebSocket connection open");
//     // Start sending data to the server
//     const data = {
//       objective: props.objective,
//       projectId: props.projectId,
//       outline: outline,
//       fileName: fileName,
//       style_prompt: props.stylePrompt,
//       token: userToken,
//       fileList: props.fileIdsForGen
//     };
//     // Send the data object as JSON
//     socket.send(JSON.stringify(data));
//   };

//   socket.onmessage = (event) => {
//     // Handle incoming messages (streaming responses from GPT-3)
//     const response = event.data;
//     if (response === "Extracting main points") setArticleLoading(true);
//     else if (response === "Generating article now") setArticleLoading(false);
//     else if (typeof response === "string") {
//       try {
//         const jsonObject = JSON.parse(response);
//         if (jsonObject.error && jsonObject.code === 500) {
//           setArticleLoading(false);
//           setError(true);
//           setErrorMsg(jsonObject.error);
//           setMenuOpen(true);
//           return;
//         }
//       } catch (error) { }
//       const formattedResponse = response.replace(/\n/g, "<br>");
//       //formattedResponse = formattedResponse.replace('-', "<br>-")
//       props.onContentChange(
//         fileName,
//         formattedResponse,
//         props.tabIndex,
//         true
//       );
//     }
//     //setContent(prevOutput => prevOutput + response); // Append the response to the existing output
//   };

//   socket.onclose = () => {
//     // Save the generated article
//     // var content = quill.getText().trim();
//     // console.log(content);
//     // var firstSentence = content.split("\n")[0];
//     // console.log(firstSentence);
//     // var firstSentenceWords = firstSentence.split(" ");
//     // console.log(firstSentenceWords)
//     // if (firstSentenceWords.length < 25) {
//     //   var firstSentenceLength = firstSentence.length;
//     //   quill.formatText(0, firstSentenceLength, "header", true);
//     // }
//     quill.formatLine(0, 1, 'header', true, "silent");   // header the first line
//     quill.setSelection(quill.getLength(), quill.getLength(), "silent");
//     props.onContentChange(
//       fileName,
//       quill.root.innerHTML,
//       props.tabIndex,
//       false
//     );
//     props.onTabLoading(false, props.tabIndex);
//     props.saveFile(quill.root.innerHTML, fileName, "article");
//     console.log("WebSocket connection closed");
//   };

//   socket.onerror = (error) => {
//     // Handle WebSocket errors
//     console.error("WebSocket error:", error);
//     setError(true);
//     setErrorMsg(error);
//   };

//   // Clean up the WebSocket connection on component unmount
//   return () => {
//     socket.close();
//     setArticleLoading(false);
//     setError(false);
//   };
// };

// const callOutlineApi = async function () {
//   setMenuOpen(false);
//   setError(false);
//   const quill = props.quillRef.current.getEditor();
//   quill.focus();
//   const fileName = "outline.txt"
//   props.onContentChange(fileName, "", props.tabIndex, false);
//   props.onTabLoading(true, props.tabIndex);
//   props.quillRef.current.scrollTop = 0; // Scroll to the top of the editor's container

//   // Calculate the scroll offset to leave 50% of white space
//   const containerHeight = props.quillRef.current.clientHeight;
//   const scrollOffset = containerHeight * 0.5;

//   props.quillRef.current.scrollTop -= scrollOffset;
//   const userToken = localStorage.getItem("token");
//   const socket = new WebSocket(streamURL + "/ai/outline");
//   socket.onopen = () => {
//     console.log("WebSocket connection open");
//     // Start sending data to the server
//     const data = {
//       objective: props.objective,
//       fileName: fileName,
//       projectId: props.projectId,
//       token: userToken,
//       fileList: props.fileIdsForGen
//     };
//     // Send the data object as JSON
//     socket.send(JSON.stringify(data));
//   };

//   socket.onmessage = (event) => {
//     // Handle incoming messages (streaming responses)
//     const response = event.data;
//     if (response === "Extracting main points") setOutlineLoading(true);
//     else if (response === "Generating outline now") setOutlineLoading(false);
//     else if (typeof response === "string") {
//       try {
//         const jsonObject = JSON.parse(response);
//         if (jsonObject.error && jsonObject.code === 500) {
//           setOutlineLoading(false);
//           setError(true);
//           setErrorMsg(jsonObject.error);
//           setMenuOpen(true);
//           return;
//         }
//       } catch (error) { }
//       const formattedResponse = response.replace(/\n/g, "<br>");
//       props.onContentChange(
//         "outline.txt",
//         formattedResponse,
//         props.tabIndex,
//         true
//       );
//       setOutline((prevOutput) => prevOutput + response);
//     }
//   };

//   socket.onclose = () => {
//     console.log("WebSocket connection closed");
//     props.onTabLoading(false, props.tabIndex);
//     setEnableArticleGen(true);
//     generateMenu(quill);
//   };

//   socket.onerror = (error) => {
//     // Handle WebSocket errors
//     console.error("WebSocket error:", error);
//   };

//   // Clean up the WebSocket connection on component unmount
//   return () => {
//     socket.close();
//     setOutlineLoading(false);
//     setError(false);
//   };
// };
