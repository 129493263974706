import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from "@carbon/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CheckmarkOutline, RepoSourceCode, Close } from "@carbon/icons-react";
import "./scss/ScopeTable.scss";

const ScopeTable = ({ data, headers }) => {
  const [tableData, setTableData] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [menuVisibility, setMenuVisibility] = useState({});
  const [sourcesDrawer, setSourcesDrawer] = useState(false);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const closeDrawer = () => {
    setSourcesDrawer(false);
  };

  const users = ["Jennifer", "Jay", "Linda", "Timothy"];

  const handleAssignedToChange = (rowIndex, newUser) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex]["Assigned to"] = newUser;
      return newData;
    });
  };

  const toggleMenu = (rowIndex) => {
    setMenuVisibility((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  };

  const toggleRow = (rowIndex) => {
    setExpandedRows((prev) => {
      const newExpandedRows = {};
      for (let key in prev) {
        newExpandedRows[key] = false;
      }
      newExpandedRows[rowIndex] = !prev[rowIndex];
      return newExpandedRows;
    });
  };

  const toggleNestedRow = (rowIndex, nestedIndex) => {
    setExpandedRows((prev) => ({
      ...prev,
      [`${rowIndex}-${nestedIndex}`]: !prev[`${rowIndex}-${nestedIndex}`],
    }));
  };

  const renderStatusIcon = (status) => {
    if (status === "complete") {
      return (
        <div
          style={{
            display: "inline-block",
          }}
        >
          <CheckmarkOutline size="20" />
        </div>
      );
    } else {
      return (
        <span
          style={{
            display: "inline-block",
            width: "1.15rem",
            height: "1.15rem",
            borderRadius: "50%",
            border: "1px solid black",
            padding: "0.5rem",
          }}
        />
      );
    }
  };

  const renderExpandedContent = (
    content,
    rowIndex,
    nestedIndex = "",
    sources = "",
    question = ""
  ) => {
    if (Array.isArray(content)) {
      return content.map((item, index) => (
        <React.Fragment key={`${rowIndex}-${nestedIndex}-${index}`}>
          <div
            onClick={() =>
              (item.expandedContent || item.suggestedAnswers) &&
              toggleNestedRow(rowIndex, `${nestedIndex}-${index}`)
            }
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
              cursor:
                item.expandedContent || item.suggestedAnswers
                  ? "pointer"
                  : "default",
              gap: "2rem",
              paddingLeft: nestedIndex ? "2rem" : "0",
              textDecoration: "underline",
            }}
          >
            {renderStatusIcon(item.status)}
            <span>{item.text}</span>
          </div>
          {item.expandedContent &&
            expandedRows[`${rowIndex}-${nestedIndex}-${index}`] && (
              <div style={{ paddingLeft: "2rem" }}>
                {renderExpandedContent(
                  item.expandedContent,
                  rowIndex,
                  `${nestedIndex}-${index}`,
                  item.sources,
                  item.question ? item.question : ""
                )}
              </div>
            )}
          {item.suggestedAnswers &&
            expandedRows[`${rowIndex}-${nestedIndex}-${index}`] && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                    fontSize: "var(--text-large)",
                    fontWeight: "500",
                  }}
                >
                  Which response do you prefer?
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    padding: "0 2rem 2rem 2rem",
                    marginBottom: "3rem",
                  }}
                >
                  {item.suggestedAnswers.map((answer, answerIndex) => (
                    <ReactQuill
                      key={answerIndex}
                      value={formatContentForQuill(answer)}
                      style={{ borderRadius: "0.5rem", flex: "1" }}
                      theme="snow"
                      readOnly={true}
                    />
                  ))}
                </div>
              </div>
            )}
        </React.Fragment>
      ));
    } else {
      return (
        <div style={{ margin: "1rem 0" }}>
          <div>
            {question && (
              <div
                style={{
                  padding: "1rem",
                  fontSize: "var(--text-medium)",
                }}
              >
                {question}
              </div>
            )}
          </div>
          {sources && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                margin: "0 0 1rem 0",
              }}
            >
              <RepoSourceCode
                onClick={() => setSourcesDrawer(true)}
                title="View sources"
              />
            </div>
          )}
          <ReactQuill
            value={formatContentForQuill(content)}
            style={{ borderRadius: "0.5rem" }}
            theme="snow"
            readOnly={false}
          />
          {sources && sourcesDrawer && (
            <div
              style={{
                position: "fixed",
                top: 70,
                right: 0,
                width: "30vw",
                height: "70vh",
                backgroundColor: "#ececec",
                boxShadow: "-2px 0 5px rgba(0,0,0,0.1)",
                padding: "20px",
                zIndex: 1001,
              }}
            >
              <button onClick={closeDrawer} style={{ float: "right" }}>
                <Close />
              </button>
              <h4>Sources</h4>
              <div
                style={{ margin: "1rem 0", overflowY: "auto", height: "60vh" }}
              >
                <p style={{ fontSize: "0.875rem", whiteSpace: "pre-wrap" }}>
                  {sources}
                </p>
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  function formatContentForQuill(content) {
    // Replace all newlines with <br> tags
    let formattedContent = content.replace(/\n/g, "<br>");

    // Wrap the entire content in paragraph tags
    formattedContent = "<p>" + formattedContent + "</p>";

    return formattedContent;
  }

  return (
    <Table
      style={{
        border: "none !important",
      }}
    >
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableHeader key={index}>{header}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            <TableRow
              style={{
                cursor: row.expandedContent ? "pointer" : "default",
                backgroundColor: "white",
                outline: "none !important",
              }}
            >
              {headers.map((header, cellIndex) => (
                <TableCell
                  key={cellIndex}
                  onClick={() =>
                    cellIndex === 1 &&
                    row.expandedContent &&
                    toggleRow(rowIndex)
                  }
                  style={{
                    cursor:
                      cellIndex === 1 && row.expandedContent
                        ? "pointer"
                        : "default",
                    textDecoration: cellIndex === 1 ? "underline" : "none",
                  }}
                >
                  {header === "Status" && typeof row[header] === "string" ? (
                    <span
                      style={{
                        display: "inline-block",
                        width: "1rem",
                        height: "1rem",
                        borderRadius: "50%",
                        backgroundColor:
                          row[header] === "Complete" ? "green" : "orange",
                        margin: "0 8px",
                      }}
                    />
                  ) : header === "Assigned to" ? (
                    <div style={{ position: "relative" }}>
                      <span
                        onClick={(e) => toggleMenu(rowIndex, e)}
                        style={{ cursor: "pointer", backgroundColor: "white" }}
                      >
                        {row[header]}
                      </span>
                      {menuVisibility[rowIndex] && (
                        <div
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            backgroundColor: "white",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            zIndex: 1000,
                          }}
                        >
                          {users
                            .filter((user) => user !== row[header])
                            .map((user, userIndex) => (
                              <div
                                key={userIndex}
                                onClick={() => {
                                  handleAssignedToChange(rowIndex, user);
                                  toggleMenu(rowIndex, {
                                    stopPropagation: () => {},
                                  });
                                }}
                                style={{
                                  padding: "8px 12px",
                                  cursor: "pointer",
                                  hover: {
                                    backgroundColor: "#f0f0f0",
                                  },
                                }}
                              >
                                {user}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    row[header]
                  )}
                </TableCell>
              ))}
            </TableRow>
            {expandedRows[rowIndex] && row.expandedContent && (
              <TableRow>
                <TableCell colSpan={headers.length}>
                  {renderExpandedContent(
                    row.expandedContent,
                    rowIndex,
                    "",
                    row.sources,
                    row.question ? row.question : ""
                  )}
                </TableCell>
              </TableRow>
            )}
            {expandedRows[rowIndex] && row.suggestedAnswers && (
              <TableRow>
                <TableCell colSpan={headers.length}>
                  <div style={{ display: "flex", gap: "2rem" }}>
                    {row.suggestedAnswers.map((answer, answerIndex) => (
                      <ReactQuill
                        key={answerIndex}
                        value={formatContentForQuill(answer)}
                        style={{ borderRadius: "0.5rem", flex: "1" }}
                        theme="snow"
                        readOnly={true}
                      />
                    ))}
                  </div>
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

export default ScopeTable;
