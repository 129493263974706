import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Settings,
  Logout,
  DataCenter,
  DocumentTasks,
  Monster,
} from "@carbon/icons-react";
import Divider from "./Divider";
import { removeToken, removeProfile } from "../store";
import supabase from "../supabaseclient";
import "./LeftPanel.scss";
import pubLogo from "../Assets/pub_logo.png";
import smallLogo from "../Assets/x_publess.png";
// import smallLogo from "../Assets/logo-x.png";
import FeedbackModal from "./FeedbackModal";
import { useLocation } from "react-router-dom";

const LeftPanel = ({ onMouseEnter, onMouseLeave, isNavOpen }) => {
  const profileRef = useRef(null);
  const [user, setUser] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const handleLogoutClick = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;

      removeToken();
      removeProfile();
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  useEffect(() => {
    const profile = localStorage.getItem("profile");
    setUser(JSON.parse(profile));

    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    if (!isNavOpen) setIsDropdownOpen(false);

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef, isNavOpen]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleHeaderClick = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  const handleOpenFeedbackModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <div
      className={`left-panel ${isNavOpen ? "nav-open" : ""}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className="logo-container"
        onClick={(event) => handleHeaderClick(event, "/rfp-dashboard")}
      >
        <img src={smallLogo} alt="logo" className="sidebar-x" />
        {isNavOpen && <img src={pubLogo} alt="logo" className="sidebar-logo" />}
      </div>
      <div className="nav-menu">
        <a
          className={`menu-item ${isActive("/rfp-dashboard") ? "active" : ""}`}
          onClick={() => handleNavigation("/rfp-dashboard")}
        >
          <DocumentTasks className="sidebar-icon" />
          {isNavOpen && <span className="menu-text">RFX</span>}
        </a>

        <a
          className={`menu-item ${isActive("/ask-archives") ? "active" : ""}`}
          onClick={() => handleNavigation("/ask-archives")}
        >
          <DataCenter className="sidebar-icon" />
          {isNavOpen && <span className="menu-text">Ask Archives</span>}
        </a>
        {/* <a
          className="menu-item research-config-navbar"
          onClick={() => window.open("https://comics.xpub.ai/", "_blank")}
        >
          <Monster className="sidebar-icon" />
          {isNavOpen && <span className="menu-text">ComicPub</span>}
        </a> */}
        <div className="bottom-section">
          <div
            ref={profileRef}
            className="profile-section"
            onClick={toggleDropdown}
          >
            <Divider />
            <div className="profile">
              <div className={`profile-initials ${isNavOpen ? "" : "closed"}`}>
                {user && user.fname && user.fname.charAt(0)}
              </div>
              {isNavOpen && (
                <span className="user-email">{user && user.fname}</span>
              )}
            </div>
            {isDropdownOpen && isNavOpen && (
              <div className="dropdown-menu">
                <span className="profile-menu-title">Account</span>
                <a
                  className="profile-menu-item"
                  onClick={() => handleNavigation("/settings")}
                >
                  <Settings />
                  Settings
                </a>
                <Divider />
                <a className="profile-menu-item" onClick={handleLogoutClick}>
                  <Logout />
                  Logout
                </a>
              </div>
            )}
            <FeedbackModal isOpen={isModalOpen} onClose={handleCloseModal} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;

{
  /* <a
          className={`menu-item dashboard-navbar  ${
            isActive("/dashboard") || isActive("/editor") ? "active" : ""
          }`}
          onClick={() => handleNavigation("/dashboard")}
        >
          <RequestQuote />
          {isNavOpen && <span className="menu-text">Projects</span>}
        </a> */
}
{
  /* <a
          className={`menu-item ${isActive("/styleprofile") ? "active" : ""}`}
          onClick={() => handleNavigation("/styleprofile")}
        >
          <UserProfile className="sidebar-icon" />
          {isNavOpen && <span className="menu-text">Templates</span>}
        </a> */
}
{
  /* <a
          className={`menu-item research-config-navbar ${
            isActive("/researchpreferences") ? "active" : ""
          }`}
          onClick={() => handleNavigation("/researchpreferences")}
        >
          <SettingsAdjust className="sidebar-icon" />
          {isNavOpen && <span className="menu-text">Research Config</span>}
        </a> */
}
