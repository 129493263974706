import axios from "axios";
import env from "./config";

let baseURL = env.baseURL;
export const streamURL = env.socketURL;
var prof = {};

export let api = axios.create({ baseURL });
export let api_external = axios.create({});

export const loginWithDataTokenAndProfile = async (token, profile) => {
  setToken(token, profile);
  setProfile(profile);
  setProfileForIntercom(profile);
};

const setProfileForIntercom = (profile) => {
  const loggedInUser = {
    name: profile.fname + " " + profile.lname,
    email: profile.email,
    user_id: profile._id,
  };
  window.Intercom("update", loggedInUser);
};

export const getProfile = () => {
  return prof;
};

export const setToken = async (token, profile) => {
  api.defaults.headers.common["x-access-token"] = token;
  api_external.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  api_external.defaults.headers.common["user_id"] = profile._id;
  localStorage.setItem("token", token);
};

export const setProfile = async (profile) => {
  prof = profile;
  localStorage.setItem("profile", JSON.stringify(profile));
};

export const removeToken = async () => {
  localStorage.removeItem("token");
};

export const removeProfile = async () => {
  prof = {};
  localStorage.removeItem("profile");
};
