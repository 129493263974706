import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef,
} from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import {
  LinkBubbleMenu,
  RichTextEditor,
  TableBubbleMenu,
  insertImages,
  FontSize,
  HeadingWithAnchor,
  LinkBubbleMenuHandler,
  ResizableImage,
  TableImproved,
  MenuButtonBold,
  MenuButtonItalic,
  MenuButtonStrikethrough,
  MenuButtonUnderline,
  MenuButtonBulletedList,
  MenuButtonOrderedList,
  MenuSelectFontFamily,
  MenuSelectFontSize,
  MenuControlsContainer,
  MenuDivider,
  MenuButton,
  MenuSelectTextAlign,
} from "mui-tiptap";
import { api_external } from "../store";
import env from "../config";
import { ListItem } from "@tiptap/extension-list-item";
import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Code } from "@tiptap/extension-code";
import { CodeBlock } from "@tiptap/extension-code-block";
import { Color } from "@tiptap/extension-color";
import { Document } from "@tiptap/extension-document";
import { Dropcursor } from "@tiptap/extension-dropcursor";
import { FontFamily } from "@tiptap/extension-font-family";
import { Gapcursor } from "@tiptap/extension-gapcursor";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Highlight } from "@tiptap/extension-highlight";
import { History } from "@tiptap/extension-history";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";
import { Italic } from "@tiptap/extension-italic";
import { Link } from "@tiptap/extension-link";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Strike } from "@tiptap/extension-strike";
import { Subscript } from "@tiptap/extension-subscript";
import { Superscript } from "@tiptap/extension-superscript";
import { TableCell } from "@tiptap/extension-table-cell";
import { TableHeader } from "@tiptap/extension-table-header";
import { TableRow } from "@tiptap/extension-table-row";
import { Text } from "@tiptap/extension-text";
import { TextAlign } from "@tiptap/extension-text-align";
import { TextStyle } from "@tiptap/extension-text-style";
import { Underline } from "@tiptap/extension-underline";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import AutoModeRoundedIcon from '@mui/icons-material/AutoModeRounded';
import { setItems, updateItem } from "../reduxstore/itemsSlice";
import { useSelector, useDispatch } from "react-redux";
import { updateItemStatus } from "../reduxstore/itemsStatusSlice";
import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

const TodoHighlight = Extension.create({
  name: "todoHighlight",

  addProseMirrorPlugins() {
    const extension = this;
    return [
      new Plugin({
        key: new PluginKey("todoHighlight"),
        props: {
          decorations: (state) => {
            const { doc } = state;
            const decorations = [];
            const regex = /\[TODO:[^\[]*?\]/g;
            doc.descendants((node, pos) => {
              if (node.isText) {
                let match;
                while ((match = regex.exec(node.text)) !== null) {
                  const start = pos + match.index;
                  const end = start + match[0].length;
                  decorations.push(
                    Decoration.inline(start, end, {
                      class: "todo-highlight",
                    })
                  );
                }
              }
            });
            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});

const CustomLinkExtension = Link.extend({
  inclusive: false,
});

const CustomSubscript = Subscript.extend({
  excludes: "superscript",
});

const CustomSuperscript = Superscript.extend({
  excludes: "subscript",
});

const CustomListItem = ListItem.extend({
  renderHTML({ HTMLAttributes }) {
    return ["li", { ...HTMLAttributes, style: "margin-left: 1em;" }, 0];
  },
});

const StyledRichTextEditor = React.forwardRef((props, ref) => (
  <RichTextEditor {...props} ref={ref} />
));

const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      class: {
        default: "custom-paragraph",
        renderHTML: (attributes) => {
          return {
            class: `custom-paragraph`,
          };
        },
      },
    };
  },
});

const CustomHeading = HeadingWithAnchor.configure({
  levels: [1, 2, 3, 4, 5, 6],
}).extend({
  addAttributes() {
    const original = this.parent?.();
    return {
      ...original,
      class: {
        default: (level) => `custom-h${level}`,
        renderHTML: (attributes) => ({
          ...attributes,
          class: `custom-h${attributes.level}`.trim(),
        }),
      },
    };
  },
});

const CustomStyledRichTextEditor = styled(StyledRichTextEditor)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  height: "inherit",
  "& .MuiTiptap-RichTextContent-root": {
    flex: 1,
    height: "inherit",
    overflowY: "auto",
    margin: "0.5rem",
    "& .custom-paragraph": {
      fontSize: "0.875rem",
      lineHeight: 1.5,
    },
    "& .custom-h1": {
      "& [data-node-view-content]": { fontSize: "1rem" },
    },
    "& .custom-h2": {
      "& [data-node-view-content]": { fontSize: "0.95rem" },
    },
    "& .custom-h3": {
      "& [data-node-view-content]": { fontSize: "0.9rem" },
    },
    "& .custom-h4": {
      "& [data-node-view-content]": { fontSize: "0.89rem" },
    },
    "& .custom-h5, & .custom-h6": {
      "& [data-node-view-content]": { fontSize: "0.875rem" },
    },

    "& .custom-heading": {
      fontWeight: "bold",
      marginBottom: "0.5em",
    },
  },
  "& .todo-highlight": {
    backgroundColor: "#d4e2e5",
    padding: "2px",
    borderRadius: "2px",
  },
});

const TipTapEditor = ({
  rteRef,
  projectId,
  archiveIds,
  refreshItems,
  showToolbar,
  displayItem,
}) => {
  const selectedItem = useSelector((state) => state.selectedItem);
  const [content, setContent] = useState("");
  const dispatch = useDispatch();
  const items = useSelector((state) => state.items);
  const contentRef = useRef("");
  const selectedItemRef = useRef(selectedItem);

  // Update content when selectedItem changes
  useEffect(() => {
    selectedItemRef.current = selectedItem;
    if (selectedItem && selectedItem[displayItem]) {
      setContent(selectedItem[displayItem]);
      contentRef.current = selectedItem[displayItem];
      // Force editor to update its content
      if (rteRef.current?.editor) {
        rteRef.current.editor.commands.setContent(selectedItem[displayItem]);
      }
    } else {
      setContent("");
      contentRef.current = "";
      // Clear editor content
      if (rteRef.current?.editor) {
        rteRef.current.editor.commands.setContent("");
      }
    }
  }, [selectedItem, displayItem]);

  const handleChange = useCallback(
    ({ editor }) => {
      contentRef.current = editor.getHTML();

      const newTodoCount =
        displayItem === "answer" ? countTodos(contentRef.current) : 0;
      dispatch(
        updateItemStatus({ uuid: selectedItem.uuid, todoCount: newTodoCount })
      );
    },
    [selectedItem]
  );

  const countTodos = (text) => {
    // const regex = /\[TODO:\s*([^\]]+)\]/g;
    const regex = /\[TODO:[^\[]*?\]/g;
    ;
    const matches = text.match(regex);
    return matches ? matches.length : 0;
  };

  const handleBlur = useCallback(() => {
    if (selectedItem && contentRef.current !== selectedItem?.[displayItem]) {
      dispatch(
        updateItem({
          uuid: selectedItem.uuid,
          key: displayItem,
          value: contentRef.current,
          projectId,
        })
      );
    }
  }, [selectedItem, dispatch]);

  useEffect(() => {
    const editor = rteRef.current?.editor;
    if (editor) {
      editor.on("blur", handleBlur);
    }
    return () => {
      if (editor) {
        editor.off("blur", handleBlur);
      }
    };
  }, [handleBlur]);

  const extensions = useMemo(
    () => [
      TableImproved.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      BulletList,
      CodeBlock,
      Document,
      HardBreak,
      CustomListItem,
      OrderedList,
      CustomSubscript,
      CustomSuperscript,
      Text,
      Bold,
      Blockquote,
      Code,
      Italic,
      Underline,
      Strike,
      CustomLinkExtension.configure({
        autolink: true,
        linkOnPaste: true,
        openOnClick: false,
      }),
      LinkBubbleMenuHandler,
      Gapcursor,
      TextAlign.configure({
        types: ["heading", "paragraph", "image"],
      }),
      CustomParagraph,
      CustomHeading,
      TextStyle,
      Color,
      FontFamily,
      FontSize,
      Highlight.configure({ multicolor: true }),
      HorizontalRule,
      ResizableImage,
      Dropcursor,
      History,
      TodoHighlight,
    ],
    []
  );

  const handleNewImageFiles = useCallback((files, insertPosition) => {
    if (!rteRef.current?.editor) {
      return;
    }

    const attributesForImageFiles = files.map((file) => ({
      src: URL.createObjectURL(file),
      alt: file.name,
    }));

    insertImages({
      images: attributesForImageFiles,
      editor: rteRef.current.editor,
      insertPosition,
    });
  }, []);

  const handleDrop = useCallback(
    (view, event, _slice, _moved) => {
      if (!(event instanceof DragEvent) || !event.dataTransfer) {
        return false;
      }

      const imageFiles = Array.from(event.dataTransfer.files).filter((file) => {
        const mimeType = (file.type || "").toLowerCase();
        return mimeType.startsWith("image/");
      });

      if (imageFiles.length > 0) {
        const insertPosition = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })?.pos;

        handleNewImageFiles(imageFiles, insertPosition);
        event.preventDefault();
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  const handlePaste = useCallback(
    (_view, event, _slice) => {
      if (!event.clipboardData) {
        return false;
      }

      const pastedImageFiles = Array.from(event.clipboardData.files).filter(
        (file) => {
          const mimeType = (file.type || "").toLowerCase();
          return mimeType.startsWith("image/");
        }
      );

      if (pastedImageFiles.length > 0) {
        handleNewImageFiles(pastedImageFiles);
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  const handleRegenerate = async () => {
    const currentSelectedItem = selectedItemRef.current;
    console.log(currentSelectedItem);
    dispatch(
      updateItemStatus({ uuid: currentSelectedItem.uuid, isGenerating: true })
    );

    try {
      const requestData = {
        project_id: projectId,
        section_id: currentSelectedItem.uuid,
        archive_ids: archiveIds,
      };

      await api_external.post(
        `${env.salesPubAPI}/regenerate-rfx-section-response`,
        requestData
      );

      dispatch(
        updateItemStatus({
          uuid: currentSelectedItem.uuid,
          isGenerating: false,
        })
      );
      refreshItems();
    } catch (error) {
      console.error("Error generating section:", error);
      dispatch(
        updateItemStatus({
          uuid: currentSelectedItem.uuid,
          isGenerating: false,
          isError: true,
        })
      );
    }
  };

  const Footer = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0.1rem",
        borderTop: "0.1rem solid #e0e0e0",
        fontSize: "0.75rem",
        
      }}
    >
      <IconButton
        size="small"
      >
        <AutoModeRoundedIcon fontSize="inherit"  />
      </IconButton>
      
      {/* <IconButton
        onClick={() => handleVersionChange(-1)}
        size="small"
        disabled={currentVersionIndex === 0 || versions.length <= 1}
      >
        <ArrowBackIosNewIcon fontSize="inherit"  />
      </IconButton>
      <Typography variant="secondaryText" sx={{ mx: 1, fontSize:"inherit"  }}>
        Version {currentVersionIndex + 1} of {versions.length}
      </Typography>
      <IconButton
        onClick={() => handleVersionChange(1)}
        size="small"
        disabled={
          currentVersionIndex === versions.length - 1 || versions.length <= 1
        }
      >
        <ArrowForwardIosIcon fontSize="inherit" />
      </IconButton> */}
    </Box>
  );

  const renderControls = useCallback(
    () => (
      <MenuControlsContainer>
        <MenuSelectFontFamily
          options={[
            { label: "Comic Sans", value: "Comic Sans MS, Comic Sans" },
            { label: "Cursive", value: "cursive" },
            { label: "Monospace", value: "monospace" },
            { label: "Serif", value: "serif" },
          ]}
        />
        <MenuDivider />
        {/* <MenuSelectHeading /> */}
        {/* <MenuDivider /> */}
        <MenuSelectFontSize />
        <MenuDivider />
        <MenuButtonBold />
        <MenuButtonItalic />
        <MenuButtonStrikethrough />
        <MenuButtonUnderline />
        <MenuDivider />
        <MenuSelectTextAlign />
        <MenuDivider />
        <MenuButtonBulletedList />
        <MenuButtonOrderedList />
        <MenuDivider />
        {/* <MenuButton
          icon={<AutoModeRoundedIcon fontSize="16" />}
          onClick={handleRegenerate}
          title="Regenerate Section"
        /> */}

      <MenuButton
      tooltipLabel="Regenerate Section"
      IconComponent={AutoModeRoundedIcon}
      onClick={handleRegenerate}
      // {...props}
      />
      </MenuControlsContainer>
    ),
    []
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "inherit",
        overflowY: "hidden",
      }}
    >
      
      <CustomStyledRichTextEditor
        key={selectedItem?.uuid} // Add this line to force re-render
        ref={rteRef}
        extensions={extensions}
        content={content}
        editable={true}
        onUpdate={handleChange}
        editorProps={{
          handleDrop: handleDrop,
          handlePaste: handlePaste,
        }}
        renderControls={showToolbar ? renderControls : null}
        
        RichTextFieldProps={{
          variant: "outlined",
          MenuBarProps: {
            hide: false,
          },
        }}
        // sx={displayItem === "answer" ? { height: "97%" } : {height: "100%"}}
      >
        {() => (
          <>
            <LinkBubbleMenu />
            <TableBubbleMenu />
          </>
        )}
      </CustomStyledRichTextEditor>
      {/* {displayItem === "answer" && (
        <Box>
          <Footer />
        </Box>
      )} */}
      {/* <Footer /> */}
    </Box>
    
  );
};
export default TipTapEditor;
