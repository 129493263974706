import React, { useEffect, useState } from "react";
import { TextField, Grid, Box, Typography, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import { api } from "../store";
import FileUploader from "../Components/FileUploader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

const CreateRFXProject = () => {
  const navigate = useNavigate();

  const [projectName, setProjectName] = useState("");
  const [clientName, setClientName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [filesAndStatus, setFilesAndStatus] = useState([]);
  const [uploadErrorMessages, setUploadErrorMessages] = useState([]);
  const [uploadError, setUploadError] = useState(false);
  const [isRFXTagUsed, setIsRFXTagUsed] = useState(false);

  const handleCancel = () => {
    navigate("/rfp-dashboard");
  };

  async function uploadFiles(filesWithTags, projectId) {
    const uploadResponses = [];

    for (const { file, tag } of filesWithTags) {
      const formData = new FormData();

      formData.append("files", file);
      formData.append("fileTag", tag === null ? "uploaded" : tag);
      formData.append("ownerId", projectId);

      try {
        const response = await api.post("file/uploadFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        uploadResponses.push(response.data);
      } catch (err) {
        console.error(`Failed to upload file: ${file.name}`, err);
        uploadResponses.push({ file: file.name, error: err.message });
      }
    }

    return uploadResponses;
  }

  const submitUploadFiles = async (projectId) => {
    let updatedFilesAndStatus = filesAndStatus.map((fs) =>
      fs.status === "edit" ? { ...fs, status: "uploading" } : fs
    );

    try {
      setFilesAndStatus(updatedFilesAndStatus);
      setUploadErrorMessages([]);

      const filesToUpload = updatedFilesAndStatus
        .filter((fs) => fs.status === "uploading")
        .map((fs) => ({
          file: fs.file,
          tag: fs.tag,
        }));

      const uploadResponses =
        filesToUpload.length > 0
          ? await uploadFiles(filesToUpload, projectId)
          : [];

      for (let [index, response] of uploadResponses.entries()) {
        if (response.status === 200) {
          updatedFilesAndStatus[index].status = "complete";
        } else {
          updatedFilesAndStatus[index].status = "edit";
          setUploadErrorMessages((prevErrorMessages) => [
            ...prevErrorMessages,
            response.message,
          ]);
        }
      }
    } catch (error) {
      updatedFilesAndStatus = filesAndStatus.map((fs) => ({
        ...fs,
        status: "edit",
      }));
      console.error("Error during file upload process", error);
      setUploadError(true);
    } finally {
      setFilesAndStatus(updatedFilesAndStatus);
      const completedFilesAndStatus = updatedFilesAndStatus.filter(
        (fs) => fs.status === "complete"
      );
      if (completedFilesAndStatus.length === filesAndStatus.length) {
        setFilesAndStatus([]);
        setUploadErrorMessages([]);
      }
    }
  };

  const handleCreate = async () => {
    const newErrors = {};

    if (!projectName) {
      newErrors.projectName = "Project Name is required.";
    }

    if (!clientName) {
      newErrors.clientName = "Client Name is required.";
    }

    if (filesAndStatus.length === 0) {
      newErrors.filesAndStatus = "At least one file must be uploaded.";
    }

    const hasRFXFile = filesAndStatus.some((file) => file.tag === "RFX");
    if (!hasRFXFile) {
      newErrors.filesAndStatus =
        "At least one file must be tagged as 'RFX File'.";
    }
    if (projectDescription.length > 100) {
      newErrors.description = "Project description can only be 100 characters";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await api.post("/project/createRFXProject", {
        name: projectName,
        client: clientName,
        description: projectDescription,
        dueDate: dueDate ? dueDate.toISOString() : null,
      });

      const projectId = response.data.projectId;
      await submitUploadFiles(projectId);

      navigate("/rfp-loader", {
        state: {
          projectId: projectId,
          projectName: response.data.projectName,
        },
      });
    } catch (error) {
      console.error("Error creating RFX project:", error);
    }
  };

  const availableTagOptions = [
    !isRFXTagUsed && { id: "RFX", label: "RFX File" },
    { id: "forms", label: "Form" },
  ].filter(Boolean);

  useEffect(() => {
    const rfxFileExists = filesAndStatus.some((file) => file.tag === "RFX");
    setIsRFXTagUsed(rfxFileExists);
  }, [filesAndStatus]);

  function updateFileUploaderStatus(updatedFilesAndStatus) {
    setUploadErrorMessages([]);
    setFilesAndStatus(updatedFilesAndStatus);
    setUploadError(false);
  }

  return (
    <Grid sx={{ p: 4 }} container>
      <Grid item xs={12} sm={8} md={6}>
        <Typography variant="h6" gutterBottom>
          Create project
        </Typography>
        <Box sx={{ mb: 10 }}>
          <Grid container spacing={2} style={{ marginTop: "0.1rem" }}>
            <Grid item xs={12} sx={{ marginTop: "10px" }}>
              <TextField
                id="project-name"
                label="Project Name"
                variant="outlined"
                fullWidth
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                error={!!errors.projectName}
                helperText={errors.projectName}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "10px" }}>
              <TextField
                id="project-client"
                label="Client Name"
                variant="outlined"
                fullWidth
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                error={!!errors.clientName}
                helperText={errors.clientName}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "10px" }}>
              <TextField
                id="project-description"
                label="Project Description"
                variant="outlined"
                fullWidth
                value={projectDescription}
                onChange={(e) => setProjectDescription(e.target.value)}
                error={!!errors.description}
                helperText={errors.description}
              />
              <Typography
                variant="caption"
                sx={{
                  display: "block",
                  textAlign: "right",
                  color:
                    projectDescription.length > 100 ? "red" : "textSecondary", // Change color to red if over 100
                }}
              >
                {projectDescription.length}/100 characters
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Due Date"
                    value={dueDate}
                    onChange={(date) => setDueDate(date)}
                    minDate={dayjs()}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Upload RFX Files
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginTop: "10px" }}
              >
                Accepted formats: .txt, .docx, .pdf | Maximum file size is 2GB.
              </Typography>
              <FileUploader
                uploadErrorMessages={uploadErrorMessages}
                filesAndStatus={filesAndStatus}
                updateFileUploaderStatus={updateFileUploaderStatus}
                maxFiles={100}
                uploadError={uploadError}
                allowAudioFiles={false}
                acceptTypes={[".txt", ".docx", ".pdf"]}
                displayTags={true}
                tagOptions={availableTagOptions}
              />
              {errors.filesAndStatus && (
                <Typography color="error">{errors.filesAndStatus}</Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
          <Button
            variant="secondary"
            onClick={handleCancel}
            sx={{
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCreate}
            sx={{
              textTransform: "none",
            }}
          >
            Create
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CreateRFXProject;
