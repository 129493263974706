import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
} from "react-router-dom";
import "./App.scss";
import Editor from "./Pages/Editor";
import Dashboard from "./Pages/Dashboard";
import SettingsPage from "./Pages/Settings";
import Archives from "./Pages/Archives";
import FactcheckingSettings from "./Pages/FactcheckingSettings";
import AddArchives from "./Pages/AddArchive";
import ArchiveFiles from "./Pages/ArchiveFiles";
import PasswordChange from "./Pages/PasswordChange";
import SalesDeck from "./Pages/SalesDeck";
import FinPub from "./Pages/FinPub";
import SalesPub from "./Pages/SalesPub";
import RFPGeneration from "./Pages/RFPGenerations";
import StyleProfile from "./Pages/StyleProfile";
import Layout from "./Layout";
import supabase from "./supabaseclient";
import { setToken } from "./store";
import ResetPassword from "./Pages/ResetPassword";
import ForgotPassword from "./Pages/ForgotPassword";
import AuthPage from "./Components/AuthComponent";
import RFPDashboard from "./Pages/RFPDashboard";
import RFPLoader from "./Pages/RFPLoader";
import RFPPage from "./Pages/RFPPage";
import CreateRFXProject from "./Pages/CreateRFXProject";
import RFPEditor from "./Pages/RFPEditor";
import { Provider } from "react-redux";
import { store } from "./reduxstore";

const App = (props) => {
  const isTokenExpired = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      const { exp } = JSON.parse(jsonPayload);
      const currentTime = Math.floor(Date.now() / 1000);

      return currentTime > exp;
    } catch (e) {
      console.error("Error decoding token:", e);
      return true;
    }
  };

  const refreshToken = async () => {
    try {
      const { data, error } = await supabase.auth.refreshSession();
      if (error) {
        throw error;
      }

      setToken(data.session.access_token);
      return data.session.access_token;
    } catch (e) {
      console.error("Error refreshing token:", e);
      onLogout();
    }
  };

  const checkTokenExpired = async () => {
    const token = localStorage.getItem("token");
    if (!token || isTokenExpired(token)) {
      try {
        const refreshedToken = await refreshToken();
        return !refreshedToken;
      } catch (err) {
        return true;
      }
    }
    return false;
  };

  const [isLoggedIn, setLoginStatus] = useState(false);

  const requiresLogin = (Component) => {
    const currentPath = window.location.pathname;
    const searchParams = window.location.search;
    const redirectUrl = `/login?redirect=${encodeURIComponent(
      currentPath + searchParams
    )}`;

    return isLoggedIn ? (
      <Layout>{Component}</Layout>
    ) : (
      <Navigate to={redirectUrl} />
    );
  };

  useEffect(() => {
    if (isLoggedIn) {
      const intervalId = setInterval(async () => {
        if (await checkTokenExpired()) {
          setLoginStatus(false);
        }
      }, 1000 * 60 * 60); // Check every 60 minutes

      return () => clearInterval(intervalId);
    }
  }, [isLoggedIn]);

  const onLogin = (token) => {
    localStorage.setItem("token", token);
    setLoginStatus(true);
  };

  const onLogout = () => {
    localStorage.removeItem("token");
    setLoginStatus(false);
  };

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<AuthPage onLogin={onLogin} />} />
          <Route path="/signup" element={<AuthPage onLogin={onLogin} />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/editor" element={requiresLogin(<Editor />)} />
          <Route path="/dashboard" element={requiresLogin(<Dashboard />)} />
          <Route
            path="/rfp-dashboard"
            element={requiresLogin(<RFPDashboard />)}
          />
          <Route path="/rfp-loader" element={requiresLogin(<RFPLoader />)} />
          <Route path="/rfp" element={requiresLogin(<RFPPage />)} />
          <Route
            path="/new-rfp"
            element={requiresLogin(<CreateRFXProject />)}
          />
          <Route path="/rfp-editor" element={requiresLogin(<RFPEditor />)} />
          <Route path="/settings" element={requiresLogin(<SettingsPage />)} />
          <Route
            path="/researchpreferences"
            element={requiresLogin(<FactcheckingSettings />)}
          />
          <Route
            path="/styleprofile"
            element={requiresLogin(<StyleProfile />)}
          />
          <Route path="/ask-archives" element={requiresLogin(<Archives />)} />
          <Route path="/archive-add" element={requiresLogin(<AddArchives />)} />
          <Route
            path="/archive-files"
            element={requiresLogin(<ArchiveFiles />)}
          />
          <Route
            path="/passchange"
            element={requiresLogin(<PasswordChange />)}
          />
          <Route path="/sales-deck" element={requiresLogin(<SalesDeck />)} />
          <Route path="/salespub" element={requiresLogin(<SalesPub />)} />
          <Route path="/finpub" element={requiresLogin(<FinPub />)} />
          <Route
            path="/generateRFP"
            element={requiresLogin(<RFPGeneration />)}
          />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
