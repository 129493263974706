import React, { useState } from "react";
import { ArrowRight } from "@carbon/icons-react";
import { Tile } from "@carbon/react";
import "./CMSTiles.scss";
import { api } from "../../store";
import { useNavigate } from "react-router-dom";

const CMSItemTile = ({
  cms,
  onSelectCMS,
  setArchiveonfiguration,
  setUploadConfigured,
}) => {
  const navigate = useNavigate();

  const handleTileClick = async () => {
    if (cms.name === "WordPress") {
      onSelectCMS("wordPress");
    } else if (cms.name === "Drupal") {
      onSelectCMS("drupal");
    } else if (cms.name === "SharePoint") {
      onSelectCMS("sharePoint");
    } else if (cms.name === "Upload Files") {
      // Add entry in Archives table
      const response = await api.post("/archives/createArchive", {
        archiveType: "uploadFiles",
      });
      setUploadConfigured(true);
      // Set archive enabled to true
      setTimeout(() => {
        setArchiveonfiguration(true);
        navigate("/archive-files");
        setUploadConfigured(false);
      }, 1000);
    } else {
      console.log(`${cms.name} clicked`);
    }
  };

  return (
    <div>
      {cms.supported ? (
        <Tile className="clickable-cms-tile" onClick={handleTileClick}>
          <img
            src={cms.logo}
            alt="Logo"
            style={{ width: "80px", height: "80px", marginBottom: "2rem" }}
          />
          <h6 style={{ fontWeight: "450" }}>{cms.name}</h6>
          <p
            style={{
              fontSize: "var(--text-medium)",
              fontWeight: "350",
              paddingTop: "1%",
              marginBottom: "2rem",
            }}
          >
            {cms.description}
          </p>
          <ArrowRight style={{ marginLeft: "90%" }} />
        </Tile>
      ) : (
        <Tile className="clickable-cms-tile disabled">
          <img
            src={cms.logo}
            alt="Logo"
            style={{ width: "50px", height: "50px", marginBottom: "2%" }}
          />
          <h6 style={{ fontWeight: "450", paddingTop: "1%" }}>{cms.name}</h6>
          <p
            style={{
              fontSize: "var(--text-medium)",
              fontWeight: "350",
              paddingTop: "1%",
              marginBottom: "10%",
            }}
          >
            {cms.description}
          </p>
          <ArrowRight style={{ marginLeft: "90%" }} />
        </Tile>
      )}
    </div>
  );
};

export default CMSItemTile;
